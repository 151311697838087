import { Message } from 'element-ui'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    socket_editBook ({ dispatch }, data) {
      dispatch('ss_editBook', { id: data._id, content: data })
    },
    socket_newBook ({ dispatch }, data) {
      dispatch('ss_newBook', data)
    },
    socket_newBooksFromAll ({ dispatch }, data) {
      data.forEach(book => {
        dispatch('ss_newBook', book)
      })
    },
    socket_editCustomer ({ dispatch }, data) {
      dispatch('ss_editCustomer', { id: data._id, content: data })
    },
    socket_newCustomer ({ dispatch }, data) {
      dispatch('ss_newCustomer', data)
        .then(() => {
          Message({
            showClose: true,
            message: 'New customer: ' + data.first + ' ' + data.surname,
            type: 'success'
          })
        })
    },
    socket_newCustomerFromAll ({ dispatch }, data) {
      data.forEach(customer => {
        dispatch('ss_newCustomer', customer)
      })
    },
    socket_newTicket ({ dispatch }, data) {
      dispatch('ss_newTicket', data)
    },
    socket_newMemberTransaction ({ dispatch }, { id, property, count }) {
      dispatch('ss_newMemberTransactiont', { id, property, count })
    },
    socket_newTransaction ({ dispatch }, data) {
      dispatch('ss_newTransactiont', data)
    }
  }
}
