import CategoryResource from '../../api/category.service'

export default {
  state: {
    categories: []
  },
  getters: {
    categoriesForSale (state) {
      return state.categories.filter(c => c.forSale)
    },
    categoriesForSelect: (state, getters) => {
      return state.categories
        .map(el => {
          return {
            value: el._id,
            label: el.name
          }
        })
    },
    categoriesForFilter: (state, getters) => {
      return state.categories
        .map(el => {
          return {
            value: el.name,
            text: el.name
          }
        })
    },
    snacksCategoryId (state) {
      let cat = state.categories.find(c => c.name === 'Snacks')
      return cat ? cat._id : -1
    },
    merchCategoryId (state) {
      let cat = state.categories.find(c => c.name === 'Merch')
      return cat ? cat._id : -1
    },
    addonsCategoryId (state) {
      let cat = state.categories.find(c => c.name === 'Addons')
      return cat ? cat._id : -1
    },
    // getCategoryNameById: state => id => {
    //   let cat = state.categories.find(c => c._id === id)
    //   return cat !== undefined ? cat.name : ''
    // },
    getCategoryNameById: (state) => state.categories.reduce((out, item) => {
      out[item._id] = item.name
      return out
    }, {}),
    // getCategoryIdByName: state => name => {
    //   let cat = state.categories.find(c => c.name === name)
    //   return cat !== undefined ? cat._id : ''
    // },
    getCategoryIdByName: (state) => state.categories.reduce((out, item) => {
      out[item.name] = item._id
      return out
    }, {})
  },
  mutations: {
    SET_CATEGORIES: (state, items) => {
      // state.categories = categories
      state.categories = items.map(item => Object.freeze(item))
    },
    ADD_CATEGORY: (state, item) => {
      // state.categories.push(category)
      state.categories.splice(state.categories.length, 0, Object.freeze(item))
    },
    EDIT_CATEGORY: (state, { id, content }) => {
      // state.categories.forEach(type => {
      //   if (type._id === id) {
      //     Object.assign(type, content)
      //   }
      // })
      let idx = state.categories.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.categories[idx], content)
        state.categories.splice(idx, 1, Object.freeze(newItem))
      }
    },
    REMOVE_CATEGORY: (state, id) => {
      let idx = state.categories.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.categories.splice(idx, 1)
      }
      // state.categories = state.categories.filter(item => item._id !== id)
    }
  },
  actions: {
    getCategories: ({ commit }) => {
      return CategoryResource.show()
        .then(response => {
          commit('SET_CATEGORIES', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    addCategory: ({ commit }, body) => {
      return CategoryResource.create(body)
        .then(response => {
          // return dispatch('getCategories')
          commit('ADD_CATEGORY', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    editCategory: ({ commit }, { id, content }) => {
      return CategoryResource.update(id, content)
        .then(() => {
          commit('EDIT_CATEGORY', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removeCategory: ({ commit }, id) => {
      return CategoryResource.delete(id)
        .then(() => {
          commit('REMOVE_CATEGORY', id)
        })
        .catch(err => {
          throw err
        })
    }
  }
}
