import HTTP from './http-common'

let CompanyResource = {
  show () { // Show all the users
    return HTTP.get('company/')
  },

  update (id, body) {
    return HTTP.put('company/' + id, body)
  },

  create (body) {
    return HTTP.post('company/', body)
  },

  delete (id) {
    return HTTP.delete('company/' + id)
  }
}

export default CompanyResource
