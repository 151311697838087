import LessonResource from '../../api/lesson.service'

export default {
  state: {
    lessons: []
  },
  getters: {
    defaultLesson (state) {
      let lesson = state.lessons.find(t => t.isDefault)
      if (lesson) {
        return lesson
      } else if (state.lessons.length > 0) {
        return state.lessons[0]
      } else {
        return { name: '', cos: 0, uid: -1 }
      }
    },
    getLessonById: (state) => state.lessons.reduce((out, item) => {
      out[item._id] = item
      return out
    }, {})
  },
  mutations: {
    SET_LESSONS: (state, items) => {
      state.lessons = items.map(item => Object.freeze(item))
    },
    ADD_LESSON: (state, item) => {
      state.lessons.splice(state.lessons.length, 0, Object.freeze(item))
    },
    EDIT_LESSON: (state, { id, content }) => {
      let idx = state.lessons.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.lessons[idx], content)
        state.lessons.splice(idx, 1, Object.freeze(newItem))
      }
    },
    REMOVE_LESSON: (state, id) => {
      let idx = state.lessons.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.lessons.splice(idx, 1)
      }
    }
  },
  actions: {
    getLessons: ({ commit }) => {
      return LessonResource.show()
        .then(response => {
          commit('SET_LESSONS', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    addLesson: ({ commit }, body) => {
      return LessonResource.create(body)
        .then(response => {
          commit('ADD_LESSON', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    editLesson: ({ commit }, { id, content }) => {
      return LessonResource.update(id, content)
        .then(() => {
          commit('EDIT_LESSON', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removeLesson: ({ dispatch, commit }, id) => {
      return LessonResource.delete(id)
        .then(() => {
          commit('REMOVE_LESSON', id)
        })
        .catch(err => {
          throw err
        })
    },

    createBasketItemLessonDefault: ({ dispatch, getters, rootGetters }) => {
      let company = rootGetters['getCompanyByCategory']['Lessons']
      dispatch('createBasketItem', {
        category: 'Lessons',
        eventId: getters.defaultLesson._id,
        name: 'Lessons',
        desc: [getters.defaultLesson.name],
        times: 0,
        rate: getters.defaultLesson.cost,
        customerId: '',
        customerName: '',
        companyId: company._id,
        companyName: company.name
      })
    }
  }
}
