import TypeResource from '../../api/type.service'

export default {
  state: {
    types: []
  },
  getters: {
    defaultType (state) {
      let typeIdx = state.types.findIndex(t => t.isDefault)
      if (typeIdx !== -1) {
        return Object.assign({ idx: typeIdx }, state.types[typeIdx])
      } else if (state.types.length > 0) {
        return Object.assign({ idx: typeIdx }, state.types[0])
      } else {
        return {}
      }
    }
    // defaultTypeId (state) {
    //   let index = state.types.findIndex(t => t.isDefault)
    //   return index !== -1 ? index : 0
    // },
    // defaultTypeName (state, getters) {
    //   return state.types.length > 0 ? state.types[getters.defaultTypeId].name : ''
    // }
  },
  mutations: {
    SET_TYPES: (state, items) => {
      state.types = items.map(item => Object.freeze(item))
    },
    ADD_TYPE: (state, item) => {
      state.types.splice(state.types.length, 0, Object.freeze(item))
    },
    EDIT_TYPE: (state, { id, content }) => {
      let idx = state.types.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.types[idx], content)
        state.types.splice(idx, 1, Object.freeze(newItem))
      }
    },
    CLEAR_TYPES: (state) => {
      state.types = []
    },
    REMOVE_TYPE: (state, id) => {
      let idx = state.types.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.types.splice(idx, 1)
      }
    }
  },
  actions: {
    getTypes: ({ commit }) => {
      return TypeResource.show()
        .then(response => {
          commit('SET_TYPES', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    createType: ({ commit }, body) => {
      return TypeResource.create(body)
        .then(response => {
          commit('ADD_TYPE', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    editType: ({ commit }, { id, content }) => {
      return TypeResource.update(id, content)
        .then(() => {
          commit('EDIT_TYPE', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removeType: ({ commit }, id) => {
      return TypeResource.delete(id)
        .then(() => {
          commit('REMOVE_TYPE', id)
        })
        .catch(err => {
          throw err
        })
    }
  }
}
