import store from '../../store'

export default (to, from, next) => {
  const basket = store.state.basket.basket
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      if (to.params.id === undefined) {
        next('/')
      }
    })
    .then(() => {
      if (basket.findIndex(b => b.basketId === to.params.id) === -1) {
        next('/')
      }
    })
    .finally(() => {
      next()
    })
}
