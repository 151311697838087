import SubscriptionResource from '../../api/subscription.service'

export default {
  state: {
    subscriptions: []
  },
  getters: {
    defaultSubscription (state) {
      let subscription = state.subscriptions.find(t => t.isDefault)
      if (subscription !== undefined) {
        return subscription
      } else if (state.subscriptions.length > 0) {
        return state.subscriptions[0]
      } else {
        return { name: '', cos: 0, uid: -1 }
      }
    },
    getSubscriptionById: (state) => state.subscriptions.reduce((out, item) => {
      out[item._id] = item
      return out
    }, {})
  },
  mutations: {
    SET_SUBSCRIPTIONS: (state, items) => {
      state.subscriptions = items.map(item => Object.freeze(item))
    },
    ADD_SUBSCRIPTION: (state, item) => {
      state.subscriptions.splice(state.subscriptions.length, 0, Object.freeze(item))
    },
    EDIT_SUBSCRIPTION: (state, { id, content }) => {
      let idx = state.subscriptions.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.subscriptions[idx], content)
        state.subscriptions.splice(idx, 1, Object.freeze(newItem))
      }
    },
    REMOVE_SUBSCRIPTION: (state, id) => {
      let idx = state.subscriptions.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.subscriptions.splice(idx, 1)
      }
    }
  },
  actions: {
    getSubscriptions: ({ commit }) => {
      return SubscriptionResource.show()
        .then(response => {
          commit('SET_SUBSCRIPTIONS', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    addSubscription: ({ commit }, body) => {
      return SubscriptionResource.create(body)
        .then(response => {
          commit('ADD_SUBSCRIPTION', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    editSubscription: ({ commit }, { id, content }) => {
      return SubscriptionResource.update(id, content)
        .then(() => {
          commit('EDIT_SUBSCRIPTION', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removeSubscription: ({ dispatch, commit }, id) => {
      return SubscriptionResource.delete(id)
        .then(() => {
          commit('REMOVE_SUBSCRIPTION', id)
        })
        .catch(err => {
          throw err
        })
    },

    createBasketItemSubscriptionDefault: ({ dispatch, getters, rootGetters }) => {
      let company = rootGetters['getCompanyByCategory']['Subscriptions']
      dispatch('createBasketItem', {
        category: 'Subscriptions',
        eventId: getters.defaultSubscription._id,
        name: 'Subscriptions',
        desc: [getters.defaultSubscription.name],
        times: 0,
        rate: getters.defaultSubscription.cost,
        customerId: '',
        customerName: '',
        companyId: company._id,
        companyName: company.name
      })
    }
  }
}
