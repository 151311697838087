import AllResource from '../../api/all.service'
import { Message } from 'element-ui'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll: ({ commit }, body) => {
      return AllResource.show(body)
        .then(response => {
          if (response.data.hasOwnProperty('calendar')) {
            commit('SET_CALENDAR', response.data.calendar)
          }
          if (response.data.hasOwnProperty('templates')) {
            commit('SET_TEMPLATES', response.data.templates)
          }
          if (response.data.hasOwnProperty('sessions')) {
            commit('SET_SESSIONS', response.data.sessions)
          }
          if (response.data.hasOwnProperty('types')) {
            commit('SET_TYPES', response.data.types)
          }
          if (response.data.hasOwnProperty('categories')) {
            commit('SET_CATEGORIES', response.data.categories)
          }
          if (response.data.hasOwnProperty('companies')) {
            commit('SET_COMPANIES', response.data.companies)
          }
          if (response.data.hasOwnProperty('lessons')) {
            commit('SET_LESSONS', response.data.lessons)
          }
          if (response.data.hasOwnProperty('subscriptions')) {
            commit('SET_SUBSCRIPTIONS', response.data.subscriptions)
          }
          if (response.data.hasOwnProperty('warehouse')) {
            commit('SET_WAREHOUSE', response.data.warehouse)
          }
          if (response.data.hasOwnProperty('groups')) {
            commit('SET_GROUPS', response.data.groups)
          }
          if (response.data.hasOwnProperty('tickets')) {
            commit('SET_TICKETS', response.data.tickets)
          }
          if (response.data.hasOwnProperty('capacity')) {
            commit('SET_CAPACITY', response.data.capacity)
          }
          if (response.data.hasOwnProperty('clients')) {
            commit('SET_MEMBERS', response.data.clients)
          }
          if (response.data.hasOwnProperty('books')) {
            commit('SET_BOOKS', response.data.books)
          }
          if (response.data.hasOwnProperty('double')) {
            commit('SET_DOUBLE', response.data.double)
          }
        })
        .catch(err => {
          throw err
        })
    },
    setUpdate: ({ commit }) => {
      return AllResource.set()
        .then(() => {
          Message({
            showClose: true,
            message: 'Success',
            type: 'success'
          })
        })
    }
  }
}
