import HTTP from './http-common'

let GroupResource = {
  show () { // Show all the users
    return HTTP.get('group/')
  },

  update (id, body) {
    return HTTP.put('group/' + id, body)
  },

  create (body) {
    return HTTP.post('group/', body)
  },

  delete (id) {
    return HTTP.delete('group/' + id)
  }
}

export default GroupResource
