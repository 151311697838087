import HTTP from './http-common'

let InvoiceResource = {
  show () { // Show all the users
    return HTTP.get('invoice/')
  },

  showByDate (date) {
    return HTTP.get('invoice/date/' + date)
  },

  showByRange (date) {
    return HTTP.get('invoice/range/' + date)
  },

  showByUid (uid) {
    return HTTP.get('invoice/' + uid)
  },

  update (id, body) {
    return HTTP.put('invoice/' + id, body)
  },

  refund (id, body) {
    return HTTP.put('invoice/refund/' + id, body)
  },

  close (id, body) {
    return HTTP.put('invoice/close/' + id, body)
  },

  create (body) {
    return HTTP.post('invoice/', body)
  },

  delete (id) {
    return HTTP.delete('invoice/' + id)
  }
}

export default InvoiceResource
