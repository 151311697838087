import HTTP from './http-common'

let BlockResource = {
  show () {
    return HTTP.get('block/')
  },

  update (id, body) {
    return HTTP.put('block/' + id, body)
  },

  create (body) {
    return HTTP.post('block/', body)
  }
}

export default BlockResource
