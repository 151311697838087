import Vue from 'vue'
import VueRouter from 'vue-router'

import operator from './operator-routers'
import admin from './admin-routers'

import beforeEachRouter from './hooks/beforeEachRouter'
import beforeEnterAuth from './hooks/beforeEnterAuth'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(VueRouter)
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
    beforeEnter: beforeEnterAuth
  }
].concat(operator, admin)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => beforeEachRouter(to, from, next))

export default router
