import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'

import AuthService from './api/auth.service'

Vue.config.productionTip = false

function initialisation () {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

(async function () {
  if (localStorage.getItem('token')) {
    try {
      const user = await AuthService.getCurrentUser()
      store.dispatch('autoLogin', user)
      initialisation()
    } catch (e) {
      initialisation()
    }
  } else {
    initialisation()
  }
})()
