export default {
  state: {
    basket: []
  },
  getters: {
    basket (state) {
      return state.basket
    },
    getBasketRowById: (state) => state.basket.reduce((out, item) => {
      out[item.basketId] = item
      return out
    }, {}),
    lastRowId: state => {
      let b = state.basket
      return b.length > 0 ? b[b.length - 1].basketId : -1
    },
    basketWaiverIds (state) {
      let row = state.basket.find(b => b.name === 'Waivers')
      return row ? row.waiversIds : []
    },
    basketFreeClients (state, getters) {
      let many = state.basket.filter(b => b.category === 'Tickets' || b.category === 'Books')
      let clientsWithTicket = many.length > 0 ? many.map(m => m.clientIds).flat() : []
      return getters.basketWaiverIds.filter(b => !clientsWithTicket.includes(b))
    },
    basketWaiwers (state, getters) {
      let row = state.basket.find(b => b.name === 'Waivers')
      if (row) {
        let filteredMembers = getters.members.filter(m => row.waiversIds.includes(m._id))
        return filteredMembers.map(m => {
          return {
            value: m._id,
            label: m.first + ' ' + m.surname
          }
        })
      } else {
        return []
      }
    }
  },
  mutations: {
    CREATE_BASKET_ITEM: (state, content) => {
      state.basket.splice(state.basket.length, 0, Object.freeze(content))
    },
    EDIT_BASKET_ITEM: (state, { basketId, content }) => {
      let idx = state.basket.findIndex(i => i.basketId === basketId)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.basket[idx], content)
        state.basket.splice(idx, 1, Object.freeze(newItem))
      }
    },
    CLEAR_BASKET: (state) => {
      state.basket = []
    }
  },
  actions: {
    createBasketItem: ({ commit, dispatch, getters }, content) => {
      const promise = new Promise((resolve) => resolve(true))
      promise
        .then(() => {
          let lastId = getters.lastRowId
          let newRow = Object.assign(content, { basketId: lastId + 1 })
          commit('CREATE_BASKET_ITEM', newRow)
        })
    },
    clearBasket: ({ commit }) => {
      commit('CLEAR_BASKET')
    },
    editBasketItem: ({ commit }, { basketId, content }) => {
      commit('EDIT_BASKET_ITEM', { basketId, content })
    },
    remoteBasketItem: async ({ commit, getters, dispatch }, { basketId }) => {
      let row = getters.basket.find(b => b.basketId === basketId)
      let content = { times: 0 }
      if (['Books'].includes(row.category)) {
        content = Object.assign(content, { clientIds: [], clientNames: [], bookId: '' })
      }
      if (['Tickets'].includes(row.category)) {
        content = Object.assign(content, { clientIds: [], clientNames: [] })
      }
      if (['Waivers'].includes(row.category)) {
        await row.waiversIds.forEach(async (w, index) => {
          await dispatch('afterRemoteWaiver', {
            id: w,
            name: row.desc[index]
          })
          await dispatch('remoteCustomerToCurrentInvoice', w)
        })
        content = Object.assign(content, { waiversIds: [], desc: [] })
      }
      commit('EDIT_BASKET_ITEM', { basketId, content })
    },
    afterChangeBasket ({ commit, getters, rootGetters }) {
      let free = getters.basketFreeClients.slice().reverse()
      getters.basket
        .filter(b => (b.category === 'Tickets' || b.category === 'Books') && b.clientIds.length < b.times)
        .forEach((b, index) => {
          let need = b.times - b.clientIds.length
          let fill = free.length >= need ? need : free.length
          let addClientIds = free.splice(free.length - fill)
          let addClientNames = addClientIds.map(c => {
            let member = rootGetters.getMemberById[c]
            return member ? member.fullName : ''
          })
          commit('EDIT_BASKET_ITEM', {
            basketId: b.basketId,
            content: {
              clientIds: [...b.clientIds, ...addClientIds],
              clientNames: [...b.clientNames, ...addClientNames]
            }
          })
        })
    },
    afterRemoteWaiver ({ commit, getters, dispatch }, { id, name }) {
      getters.basket
        .filter(b => (b.category === 'Tickets' || b.category === 'Books') && b.clientIds.length > 0)
        .forEach(b => {
          let clientIds = b.clientIds.filter(i => i !== id)
          let clientNames = b.clientNames.filter(n => n.trim() !== name.trim())
          commit('EDIT_BASKET_ITEM', { basketId: b.basketId, content: { clientIds, clientNames } })
        })
    },
    addWaiwerToBasket: async ({ dispatch, commit, getters, rootGetters }, { code, isRemove }) => {
      let member = rootGetters.getMemberById[code.trim()]
      if (member) {
        let row = getters.basket.find(b => b.category === 'Waivers')
        if (row) {
          if (!row.waiversIds.includes(member._id)) {
            let fullName = member.hasOwnProperty('surname') ? member.first + ' ' + member.surname : member.first
            let desc = [...row.desc, fullName]
            let waiversIds = [...row.waiversIds, member._id]
            if (member.hasOwnProperty('children') && member.children.length > 0) {
              member.children.forEach((c, index) => {
                if (c.isActive && !row.waiversIds.includes(c._id)) {
                  let fullName = c.hasOwnProperty('surname') ? c.first + ' ' + c.surname : c.first
                  desc.push(fullName)
                  waiversIds.push(c._id)
                }
              })
            }
            await dispatch('editBasketItem', {
              basketId: row.basketId,
              content: {
                desc: desc,
                times: waiversIds.length,
                waiversIds: waiversIds
              }
            })
              .then(async () => {
                let books = rootGetters.getBooksFromCustomerId[rootGetters.today + '-' + member._id]
                if (books) {
                  await books
                    .filter(b => b.useStatus === 'booked')
                    .filter(b => !getters.basket.some(r => r.category === 'Books' && r.bookId === b._id))
                    .forEach(async b => {
                      if (b.session.idx >= rootGetters.currentSessionIndex) {
                        await dispatch('createBasketItemBookDefault', {
                          book: b, customerId: member._id
                        })
                      }
                    })
                }
              })
              .then(() => {
                dispatch('setCustomerToCurrentInvoice', member)
              })
          } else {
            if (isRemove) {
              let fullName = member.hasOwnProperty('surname') ? member.first + ' ' + member.surname : member.first
              await dispatch('editBasketItem', {
                basketId: row.basketId,
                content: {
                  desc: row.desc.filter(e => e.trim() !== fullName.trim()),
                  times: row.times - 1,
                  waiversIds: row.waiversIds.filter(e => e !== member._id)
                }
              })
              await dispatch('afterRemoteWaiver', { id: member._id, name: fullName })
            }
          }
        } else {
          let fullName = member.hasOwnProperty('surname') ? member.first + ' ' + member.surname : member.first
          let desc = [fullName]
          let waiversIds = [member._id]
          if (member.hasOwnProperty('children') && member.children.length > 0) {
            member.children.forEach((c, index) => {
              if (c.isActive) {
                let fullName = c.hasOwnProperty('surname') ? c.first + ' ' + c.surname : c.first
                desc.push(fullName)
                waiversIds.push(c._id)
              }
            })
          }
          await dispatch('createBasketItem', {
            category: 'Waivers',
            name: 'Waivers',
            desc: desc,
            times: waiversIds.length,
            rate: 0,
            waiversIds: waiversIds
          })
            .then(async () => {
              let books = rootGetters.getBooksFromCustomerId[rootGetters.today + '-' + member._id]
              if (books) {
                await books
                  .filter(b => b.useStatus === 'booked')
                  .filter(b => !getters.basket.some(r => r.category === 'Books' && r.bookId === b._id))
                  .forEach(async b => {
                    if (b.session.idx >= rootGetters.currentSessionIndex) {
                      await dispatch('createBasketItemBookDefault', {
                        book: b, customerId: member._id
                      })
                    }
                  })
              }
            })
            .then(() => {
              dispatch('setCustomerToCurrentInvoice', member)
            })
        }
      }
    }
  }
}
