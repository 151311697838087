import store from '../../store'

export default (to, from, next) => {
  const calendar = store.state.calendar.calendar
  const templates = store.state.templates.templates
  const types = store.state.type.types
  const categories = store.state.category.categories
  const companies = store.state.company.companies
  const lessons = store.state.lesson.lessons
  const warehouse = store.state.warehouse.warehouse
  const sessions = store.state.session.sessions
  const clients = store.state.members.members
  const tickets = store.state.ticket.tickets
  const groups = store.state.group.groups
  const subscriptions = store.state.subscription.subscriptions
  const books = store.state.booking.books
  const currentInvoice = store.state.invoice.currentInvoice || false
  const double = !store.state.double.double.isDownload
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      const data = {
        calendar: calendar.length === 0,
        templates: templates.length === 0,
        sessions: sessions.length === 0,
        types: types.length === 0,
        categories: categories.length === 0,
        companies: companies.length === 0,
        lessons: lessons.length === 0,
        warehouse: warehouse.length === 0,
        clients: clients.length === 0,
        tickets: tickets.length === 0,
        groups: groups.length === 0,
        subscriptions: subscriptions.length === 0,
        books: books.length === 0,
        double
      }
      if (Object.values(data).some(x => x)) {
        return store.dispatch('getAll', data)
      }
    })
    .then(() => {
      if (!currentInvoice) {
        return store.dispatch('addInvoice')
      }
    })
    .then(() => {
      return store.dispatch('getCapacity', new Date().toISOString().slice(0, 10))
    })
    .finally(() => {
      next()
    })
}
