import TemplateResource from '../../api/template.service'

export default {
  state: {
    templates: []
  },
  getters: {},
  mutations: {
    SET_TEMPLATES: (state, items) => {
      // state.templates = templates
      state.templates = items.map(item => Object.freeze(item))
    },
    REMOVE_TEMPLATE: (state, id) => {
      let idx = state.templates.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.templates.splice(idx, 1)
      }
      // state.templates = state.templates.filter(item => item._id !== id)
    },
    UPDATE_TEMPLATE: (state, { id, content }) => {
      // state.templates.forEach(t => {
      //   if (t._id === id) {
      //     Object.assign(t, content, {
      //       sessions: content.sessions.map((x) => Object.assign({}, x))
      //     })
      //   }
      // })
      let idx = state.templates.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.templates[idx], content, {
          sessions: content.sessions.map((x) => Object.assign({}, x))
        })
        state.templates.splice(idx, 1, Object.freeze(newItem))
      }
    },
    // SAVE_AS_TEMPLATE: (state, template) => {
    //   state.templates.splice(state.templates.length, 0, template)
    // },
    ADD_TEMPLATE: (state, item) => {
      state.templates.splice(state.templates.length, 0, Object.freeze(item))
      // state.templates.push(template)
    }
  },
  actions: {
    getTemplates: ({ commit }) => {
      return TemplateResource.show()
        .then(response => {
          commit('SET_TEMPLATES', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    removeTemplate: ({ commit }, id) => {
      return TemplateResource.delete(id)
        .then(response => {
          commit('REMOVE_TEMPLATE', id)
          return response.data
        })
        .catch(err => {
          throw err
        })
    },
    saveTemplate: ({ commit }, { id, content }) => {
      return TemplateResource.update(id, content)
        .then(() => {
          commit('UPDATE_TEMPLATE', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    createTemplate: ({ commit }, body) => {
      return TemplateResource.create(body)
        .then(response => {
          commit('ADD_TEMPLATE', response.data)
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
  }
}
