import store from '../../store'

export default (to, from, next) => {
  const books = store.state.booking.books || []
  const tickets = store.state.ticket.tickets || []
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      if (books.length === 0) {
        return store.dispatch('getBooks')
      }
    })
    .then(() => {
      if (tickets.length === 0) {
        return store.dispatch('getTickets')
      }
    })
    .finally(() => {
      next()
    })
}
