import HTTP from './http-common'

let SessionResource = {
  show () { // Show all the users
    return HTTP.get('session/')
  },

  update (id, body) {
    return HTTP.put('session/' + id, body)
  },

  create (body) {
    return HTTP.post('session/', body)
  },

  delete (id) {
    return HTTP.delete('session/' + id)
  }
}

export default SessionResource
