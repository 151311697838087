import DoubleResource from '../../api/double.service'

export default {
  state: {
    double: {
      isDownload: false,
      double: 17.5,
      refundBooking: 24,
      editBooking: 24,
      blockBooking: 60
    }
  },
  getters: {
  },
  mutations: {
    SET_DOUBLE: (state, double) => {
      state.double = Object.assign(state.double, double[0], { isDownload: true })
    },
    ADD_DOUBLE: (state, double) => {
      state.double = Object.assign(state.double, double)
    }
  },
  actions: {
    getDouble: ({ commit }) => {
      return DoubleResource.show()
        .then(response => {
          commit('SET_DOUBLE', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    changeDouble: ({ commit }, body) => {
      return DoubleResource.create(body)
        .then(response => {
          commit('ADD_DOUBLE', response.data)
        })
        .catch(err => {
          throw err
        })
    }
  }
}
