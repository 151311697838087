import store from '../../store'

export default (to, from, next) => {
  const categories = store.state.category.categories || []
  const companies = store.state.company.companies || []
  const warehouse = store.state.warehouse.warehouse || []
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      if (categories.length === 0) {
        return store.dispatch('getCategories')
      }
    })
    .then(() => {
      if (companies.length === 0) {
        return store.dispatch('getCompanies')
      }
    })
    .then(() => {
      if (warehouse.length === 0) {
        return store.dispatch('getWarehouse')
      }
    })
    .finally(() => {
      next()
    })
}
