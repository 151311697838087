import InvoiceResource from '../../api/invoice.service'

export default {
  state: {
    invoices: [],
    currentInvoice: false
  },
  getters: {
    currentInvoice: state => state.currentInvoice,
    currentInvoiceUid (state) {
      return state.currentInvoice && state.currentInvoice.hasOwnProperty('uid') ? state.currentInvoice.uid : -1
    },
    getInvoiceByUid: (state) => state.invoices.reduce((out, item) => {
      out[item.uid] = item
      return out
    }, {}),
    invoices (state, getters, rootState) {
      const companiesNameArray = rootState.company.companies.map(c => c.name)
      return state.invoices.filter(i => i.hasOwnProperty('closeDate')).map(i => {
        let obj = {}
        companiesNameArray.forEach(name => {
          obj[name] = i.hasOwnProperty('totalByCompanies') && i.totalByCompanies.hasOwnProperty(name) ? i.totalByCompanies[name] : 0
        })
        obj['cash'] = i.hasOwnProperty('totalByPaymentMethod') && i.totalByPaymentMethod.hasOwnProperty('cash') ? i.totalByPaymentMethod['cash'] : 0
        obj['card'] = i.hasOwnProperty('totalByPaymentMethod') && i.totalByPaymentMethod.hasOwnProperty('card') ? i.totalByPaymentMethod['card'] : 0

        let waivers = []
        let sales = []
        if (i.hasOwnProperty('basket')) {
          let basketCell = i.basket.find(b => b.category === 'Waivers')
          if (basketCell) {
            waivers = basketCell.desc
          }
          let salesObj = i.basket.reduce((r, a) => {
            if (a.hasOwnProperty('category') && a.category !== 'Waivers' && a.times > 0) {
              r[a.category] = r[a.category] || { times: 0, sum: 0 }
              r[a.category].sum += a.times * a.rate
              r[a.category].times += a.times
            }
            return r
          }, {})
          sales = Object.entries(salesObj).map(s => [s[0] + '(' + s[1].times + '):', s[1].sum])
        }
        return Object.assign({
          uid: i.uid,
          date: i.openDate,
          total: i.total,
          pos: i.posName,
          author: i.author,
          memo: i.memo,
          isRefund: i.isRefund || false
        }, obj, { waivers, sales })
      })
    }
  },
  mutations: {
    SET_INVOICES: (state, items) => {
      state.invoices = items.map(item => Object.freeze(item))
    },
    ADD_INVOICE: (state, item) => {
      state.invoices.splice(state.invoices.length, 0, Object.freeze(item))
      state.currentInvoice = item
    },
    ADD_REFUND: (state, item) => {
      state.invoices.splice(state.invoices.length, 0, Object.freeze(item))
    },
    ADD_INVOICES: (state, items) => {
      items.forEach(i => {
        let idx = state.invoices.findIndex(ii => ii.uid === i.uid)
        if (idx !== -1) {
          state.invoices.splice(idx, 1, Object.freeze(i))
        } else {
          state.invoices.splice(state.invoices.length, 0, Object.freeze(i))
        }
      })
    },
    EDIT_INVOICE: (state, { id, content }) => {
      let idx = state.invoices.findIndex(l => l.uid === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.invoices[idx], content)
        state.invoices.splice(idx, 1, Object.freeze(newItem))
      }
      state.currentInvoice = Object.assign({}, state.currentInvoice, content)
    },
    REMOVE_INVOICE: (state, id) => {
      let idx = state.invoices.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.invoices.splice(idx, 1)
      }
    }
  },
  actions: {
    getInvoices: ({ commit }) => {
      return InvoiceResource.show()
        .then(response => {
          commit('SET_INVOICES', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    invoiceByUid: ({ commit, getters }, uid) => {
      let invoice = getters.getInvoiceByUid[uid]
      if (!invoice) {
        return InvoiceResource.showByUid(uid)
          .then(response => {
            commit('ADD_INVOICES', response.data)
            return response.data[0]
          })
          .catch(err => {
            throw err
          })
      } else {
        return invoice
      }
    },
    getInvoicesByDate: ({ commit }, date) => {
      return InvoiceResource.showByDate(date)
        .then(response => {
          commit('ADD_INVOICES', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    getInvoicesByRange: ({ commit }, range) => {
      return InvoiceResource.showByRange(range)
        .then(response => {
          commit('ADD_INVOICES', response.data)
          return response.data
        })
        .catch(err => {
          throw err
        })
    },
    addInvoice: ({ commit }) => {
      let body = { posName: localStorage.getItem('posname') || '' }
      return InvoiceResource.create(body)
        .then(response => {
          commit('ADD_INVOICE', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    refundedInvoice: ({ commit }, { id, content }) => {
      let body = Object.assign(content, { posName: localStorage.getItem('posname') || '' })
      return InvoiceResource.refund(id, body)
        .then(response => {
          commit('ADD_REFUND', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    editInvoice: ({ commit, dispatch }, { id, content }) => {
      return InvoiceResource.update(id, content)
        .then(response => {
          commit('EDIT_INVOICE', { id, content: response.data })
        })
        .catch(err => {
          throw err
        })
    },
    closeInvoice: ({ commit, dispatch }, { id, content }) => {
      return InvoiceResource.close(id, content)
        .then(() => {
          commit('EDIT_INVOICE', { id, content })
        })
        .then(() => {
          return dispatch('addInvoice')
        })
        .catch(err => {
          throw err
        })
    },
    removeInvoice: ({ commit }, id) => {
      return InvoiceResource.delete(id)
        .then(() => {
          commit('REMOVE_INVOICE', id)
        })
        .catch(err => {
          throw err
        })
    },
    setCustomerToCurrentInvoice: ({ commit, getters }, member) => {
      if (!getters.currentInvoice.hasOwnProperty('customerId') || getters.currentInvoice.customerId === '') {
        commit('EDIT_INVOICE', {
          id: getters.currentInvoice.uid,
          content: {
            customerId: member._id,
            customerName: member.hasOwnProperty('surname') ? member.first + ' ' + member.surname : member.first,
            customerFirst: member.first,
            customerEmail: member.email
          }
        })
      }
    },
    remoteCustomerToCurrentInvoice: ({ commit, getters, rootGetters }, id) => {
      if (getters.currentInvoice.hasOwnProperty('customerId') && getters.currentInvoice.customerId === id) {
        let freeWaivers = rootGetters.basketWaiverIds.filter(w => {
          let m = rootGetters.getMemberById[w]
          return m && (!m.hasOwnProperty('isChild') || !m.isChild)
        })
        if (freeWaivers.length > 0) {
          let member = rootGetters.getMemberById[freeWaivers[0]]
          commit('EDIT_INVOICE', {
            id: getters.currentInvoice.uid,
            content: {
              customerId: member._id,
              customerName: member.hasOwnProperty('surname') ? member.first + ' ' + member.surname : member.first,
              customerFirst: member.first,
              customerEmail: member.email
            }
          })
        } else {
          commit('EDIT_INVOICE', {
            id: getters.currentInvoice.uid,
            content: {
              customerId: '',
              customerName: '',
              customerFirst: '',
              customerEmail: ''
            }
          })
        }
      }
    }
  }
}
