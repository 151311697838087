import store from '../../store'

export default (to, from, next) => {
  const promocodes = store.state.promocode.promocodes || []
  const clients = store.state.members.members || []
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      if (clients.length === 0) {
        return store.dispatch('getMembers')
      }
    })
    .then(() => {
      if (promocodes.length === 0) {
        return store.dispatch('getPromos')
      }
    })
    .finally(() => {
      next()
    })
}
