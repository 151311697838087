import store from '../../store'

export default (to, from, next) => {
  const calendar = store.state.calendar.calendar || []
  const blocks = store.state.calendar.blocks || []
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      if (blocks.length === 0) {
        return store.dispatch('getBlocks')
      }
    })
    .then(() => {
      if (calendar.length === 0) {
        return store.dispatch('getCalendar')
      }
    })
    .then(() => {
      return store.dispatch('getCapacityMonth')
    })
    .then(() => {
      return store.dispatch('getBooks')
    })
    .finally(() => {
      next()
    })
}
