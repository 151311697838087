import HTTP from './http-common'

let TemplateResource = {
  show () { // Show all the users
    return HTTP.get('template/')
  },

  update (id, body) {
    return HTTP.put('template/' + id, body)
  },

  create (body) {
    return HTTP.post('template/', body)
  },

  delete (id) {
    return HTTP.delete('template/' + id)
  }
}

export default TemplateResource
