import store from '../../store'

export default (to, from, next) => {
  const lessons = store.state.lesson.lessons || []
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      if (lessons.length === 0) {
        return store.dispatch('getLessons')
      }
    })
    .finally(() => {
      next()
    })
}
