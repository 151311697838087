<template>
    <vue-scroll class="page">
        <div class="flex gaps">
            <div class="box flex column session clickable"
              @click="chooseSession(index)"
              :class="{
                'locked-panel': currentSessionIndex > index || currentSessionIndex === -1,
                'locked-warning-panel' : isBlock(item)
              }"
             v-for="(item, index) in todaySessions" :key="index">
                <div class="box font-size-11 o-050">{{item.time}}</div>
                <div class="box font-size-12 center" :class="capacityColor(item.left)">{{item.left}} / {{item.capacity}}</div>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Sessions',

  computed: {
    ...mapGetters([
      'todaySessions',
      'currentSessionIndex'
    ])
  },
  methods: {
    capacityColor (capacity) {
      let color
      switch (true) {
        case capacity >= 0 && capacity <= 10:
          color = 'danger-text'
          break
        case capacity >= 11 && capacity <= 20:
          color = 'warning-text'
          break
        default:
          color = 'success-text'
      }
      return color
    },
    chooseSession (index) {
      this.$onCommandParams('sessionInfo', { id: index })
    },
    isBlock (item) {
      return item.hasOwnProperty('block') &&
        item.block.hasOwnProperty('isBlock') &&
        item.block.isBlock
    }
  }
}
</script>
