import HTTP from './http-common'

let DoubleResource = {
  show () {
    return HTTP.get('double/')
  },

  create (body) {
    return HTTP.post('double/', body)
  }
}

export default DoubleResource
