import GroupResource from '../../api/group.service'

export default {
  state: {
    groups: []
  },
  getters: {
    groupsOneClick (state) {
      return state.groups.filter(g => g.isOneClick)
    },
    groupsForSelect: (state, getters) => {
      return [{
        value: '',
        label: ''
      }].concat(state.groups
        .map(el => {
          return {
            value: el._id,
            label: el.name
          }
        }))
    },
    groupsForFilter: (state, getters) => {
      return state.groups
        .map(el => {
          return {
            value: el.name,
            text: el.name
          }
        })
    },
    getGroupById: (state) => state.groups.reduce((out, item) => {
      out[item._id] = item
      return out
    }, {}),
    getGroupIdByName: (state) => state.groups.reduce((out, item) => {
      out[item.name] = item._id
      return out
    }, {})
  },
  mutations: {
    SET_GROUPS: (state, items) => {
      state.groups = items.map(item => Object.freeze(item))
    },
    ADD_GROUP: (state, item) => {
      state.groups.splice(state.groups.length, 0, Object.freeze(item))
    },
    EDIT_GROUP: (state, { id, content }) => {
      let idx = state.groups.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.groups[idx], content)
        state.groups.splice(idx, 1, Object.freeze(newItem))
      }
    },
    REMOVE_GROUP: (state, id) => {
      let idx = state.groups.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.groups.splice(idx, 1)
      }
    }
  },
  actions: {
    getGroups: ({ commit }) => {
      return GroupResource.show()
        .then(response => {
          commit('SET_GROUPS', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    addGroup: ({ commit }, body) => {
      return GroupResource.create(body)
        .then(response => {
          commit('ADD_GROUP', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    editGroup: ({ commit }, { id, content }) => {
      return GroupResource.update(id, content)
        .then(() => {
          commit('EDIT_GROUP', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removeGroup: ({ dispatch, commit }, id) => {
      return GroupResource.delete(id)
        .then(() => {
          commit('REMOVE_GROUP', id)
        })
        .catch(err => {
          throw err
        })
    }
  }
}
