import HTTP from './http-common'

let LessonResource = {
  show () { // Show all the users
    return HTTP.get('lesson/')
  },

  update (id, body) {
    return HTTP.put('lesson/' + id, body)
  },

  create (body) {
    return HTTP.post('lesson/', body)
  },

  delete (id) {
    return HTTP.delete('lesson/' + id)
  }
}

export default LessonResource
