import HTTP from './http-common'

let AllResource = {
  show (body) {
    return HTTP.post('all/', body)
  },

  set () {
    return HTTP.post('all/set/')
  }
}

export default AllResource
