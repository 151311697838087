// import beforeEnterCalendar from './hooks/beforeEnterCalendar'
import beforeEnterTickets from './hooks/beforeEnterTickets'
import beforeEnterHome from './hooks/beforeEnterHome'
import beforeEnterEvents from './hooks/beforeEnterEvents'
import beforeEnterWarehouse from './hooks/beforeEnterWarehouse'
import beforeEnterWaivers from './hooks/beforeEnterWaivers'
import beforeEnterSessionInfo from './hooks/beforeEnterSessionInfo'

export default [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "operator" */ '../views/operator/Operator.vue'),
    beforeEnter: beforeEnterHome,
    meta: {
      auth: true,
      roles: ['admin', 'user']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "sale" */ '../views/operator/Sale.vue'),
        meta: {
          auth: true,
          roles: ['admin', 'user']
        },
        children: [
          {
            path: '',
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */ '../views/operator/sales/Home.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'sessionInfo/:id',
            name: 'sessionInfo',
            beforeEnter: beforeEnterSessionInfo,
            component: () => import(/* webpackChunkName: "sessionInfo" */ '../views/operator/sales/SessionInfo.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'saleTickets/:id',
            name: 'saleTickets',
            beforeEnter: beforeEnterTickets,
            component: () => import(/* webpackChunkName: "saleTickets" */ '../views/operator/sales/SaleTickets.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'saleBooks/:id',
            name: 'saleBooks',
            beforeEnter: beforeEnterTickets,
            component: () => import(/* webpackChunkName: "saleBooks" */ '../views/operator/sales/SaleBooks.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'saleBooking/:id',
            name: 'saleBooking',
            beforeEnter: beforeEnterTickets,
            component: () => import(/* webpackChunkName: "saleBooking" */ '../views/operator/sales/booking/SaleBooking.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'saleLessons/:id',
            name: 'saleLessons',
            beforeEnter: beforeEnterTickets,
            component: () => import(/* webpackChunkName: "saleLessons" */ '../views/operator/sales/SaleLessons.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'saleSubscriptions/:id',
            name: 'saleSubscriptions',
            beforeEnter: beforeEnterTickets,
            component: () => import(/* webpackChunkName: "saleSubscriptions" */ '../views/operator/sales/SaleSubscriptions.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'listOfEvents',
            name: 'listOfEvents',
            beforeEnter: beforeEnterEvents,
            component: () => import(/* webpackChunkName: "listOfEvents" */ '../views/operator/sales/events/ListOfEvents.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'createEvent',
            name: 'createEvent',
            beforeEnter: beforeEnterEvents,
            component: () => import(/* webpackChunkName: "createEvent" */ '../views/operator/sales/events/CreateEvent.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'saleEvent/:id',
            name: 'saleEvent',
            beforeEnter: beforeEnterEvents,
            component: () => import(/* webpackChunkName: "saleEvent" */ '../views/operator/sales/SaleEvent.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'saleAddons',
            name: 'saleAddons',
            beforeEnter: beforeEnterWarehouse,
            component: () => import(/* webpackChunkName: "saleAddons" */ '../views/operator/sales/SaleAddons.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'saleSnacks',
            name: 'saleSnacks',
            beforeEnter: beforeEnterWarehouse,
            component: () => import(/* webpackChunkName: "saleSnacks" */ '../views/operator/sales/SaleSnacks.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'saleMerch',
            name: 'saleMerch',
            beforeEnter: beforeEnterWarehouse,
            component: () => import(/* webpackChunkName: "saleMerch" */ '../views/operator/sales/SaleMerch.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'waivers',
            name: 'waivers',
            beforeEnter: beforeEnterWaivers,
            component: () => import(/* webpackChunkName: "waivers" */ '../views/operator/sales/waivers/Waivers.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'waiver/:id',
            name: 'waiver',
            beforeEnter: beforeEnterWaivers,
            component: () => import(/* webpackChunkName: "waiver" */ '../views/operator/sales/waivers/Waiver.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          },
          {
            path: 'createWaiver',
            name: 'createWaiver',
            beforeEnter: beforeEnterWaivers,
            component: () => import(/* webpackChunkName: "createWaiver" */ '../views/operator/sales/waivers/CreateWaiver.vue'),
            meta: {
              auth: true,
              roles: ['admin', 'user']
            }
          }
        ]
      }
    ]
  },
  {
    name: 'booking',
    path: '/booking',
    component: () => import(/* webpackChunkName: "bookingCalendar" */ '../views/operator/sales/booking/BookingCalendar.vue'),
    meta: {
      auth: true,
      roles: ['admin', 'user']
    }
  }
]
