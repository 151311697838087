<template>
  <div class="footer flex align-center justify-space-between">
    <app-sessions v-if="$route.name !== 'login' && $route.name !== 'posname'"></app-sessions>
    <div class="box flex gaps">
      <div class="box center">© Rolldxb</div>
      <div class="box center" @click="$emit('fullscreen')">
        <i :class="[fullscreen ? 'mdi mdi-fullscreen-exit' : 'mdi mdi-fullscreen', 'fs-24 like-button']"></i>
      </div>
      <div class="box center" @click="onClickLogout"><i class="mdi mdi-logout fs-24 like-button"></i></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import AppSessions from '../views/components/Sessions'

export default {
  name: 'Footer',

  components: {
    AppSessions
  },

  props: {
    fullscreen: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },

  methods: {
    ...mapActions(['logout']),
    onClickLogout () {
      this.logout()
        .then(() => {
          this.$onCommandParams('login')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/scss/_variables';
  @import '../assets/scss/_mixins';

  .footer {
    width: 100%;
    height: 40px;
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 2px;
    font-size: 14px;
    border-top-left-radius: 20px;

    a {
      font-weight: bold;
      text-decoration: none;
      color: $text-color-accent;
    }

    .buy-btn {
      margin-left: 10px;
    }
    .like-button {
      cursor: pointer;
      &:hover {
        color: mix(white, $text-color-accent, 30%);
      }
    }
  }

  @media (max-width: 768px) {
    .footer {
      padding: 0 12px;
      border-radius: 0;
    }
  }
</style>
