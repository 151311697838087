import HTTP from './http-common'

let EventResource = {
  show () { // Show all the users
    return HTTP.get('event/')
  },

  update (id, body) {
    return HTTP.put('event/' + id, body)
  },

  create (body) {
    return HTTP.post('event/', body)
  },

  delete (id) {
    return HTTP.delete('event/' + id)
  }
}

export default EventResource
