import CalendarResource from '../../api/calendar.service'
import BlockResource from '../../api/block.service'

export default {
  state: {
    calendar: [],
    blocks: []
  },
  getters: {
    today () {
      let now = new Date()
      let today = new Date(now.setHours(now.getHours() - 6))
      return new Date(
        new Date(today
          .setHours(0, 0, 0, 0))
          .toString()
          .split('GMT')[0] + ' UTC')
        .toISOString().slice(0, 10)
    },
    todaySessions (state, getters, rootState) {
      let dayObj = state.calendar.find(c => c.day === getters.today)
      let capacity = rootState.ticket.capacity
      return dayObj && dayObj.hasOwnProperty('sessions') ? dayObj.sessions.map((s, index) => {
        let cap = capacity.length > 0
          ? capacity.find(c => c.day.slice(0, 10) === getters.today && c.session === index)
          : undefined
        return cap
          ? Object.assign({ left: s.capacity - cap.persons }, s)
          : Object.assign({ left: s.capacity }, s)
      }) : []
    },
    sessionsByDay: (state, getters, rootState) => {
      let capacity = rootState.ticket.capacity

      return state.calendar.reduce((out, item) => {
        out[item.day] = item.hasOwnProperty('sessions')
          ? item.sessions.map((s, index) => {
            let cap = capacity.length > 0
              ? capacity.find(c => c.day.slice(0, 10) === item.day && c.session === index)
              : undefined

            return cap
              ? Object.assign({ left: s.capacity - cap.persons }, s)
              : Object.assign({ left: s.capacity }, s)
          }) : []

        return out
      }, {})
    },
    currentSessionIndex: (state, getters) => {
      let currentTime = new Date()
      return getters.todaySessions.findIndex(s => {
        let times = s.time.split(' - ')
        let startTime = new Date(getters.today)
        let start = times[0].split(':')
        if (start[0] < 6) {
          startTime.setDate(startTime.getDate() + 1)
        }
        startTime.setHours(start[0])
        startTime.setMinutes(start[1])
        let endTime = new Date(getters.today)
        let end = times[1].split(':')
        if (end[0] < 6) {
          endTime.setDate(endTime.getDate() + 1)
        }
        endTime.setHours(end[0])
        endTime.setMinutes(end[1])
        return (currentTime >= startTime && currentTime < endTime) || currentTime <= startTime
      })
    }
  },
  mutations: {
    SET_BLOCKS: (state, items) => {
      state.blocks = items.map(item => Object.freeze(item))
    },
    ADD_BLOCK: (state, item) => {
      let idx = state.blocks.findIndex(l => l._id === item._id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.blocks[idx], item)
        state.blocks.splice(idx, 1, Object.freeze(newItem))
      } else {
        state.blocks.splice(state.blocks.length, 0, Object.freeze(item))
      }
    },
    UPDATE_CALENDAR_BLOCK: (state, data) => {
      let idx = state.calendar.findIndex(c => c.day === data.day)
      if (idx !== -1) {
        let day = JSON.parse(JSON.stringify(state.calendar[idx]))
        let newSession = Object.assign(
          {},
          day.sessions[data.sessionIdx],
          { block: data }
        )
        day.sessions.splice(data.sessionIdx, 1, Object.freeze(newSession))
        state.calendar.splice(idx, 1, Object.freeze(day))
      }
    },
    SET_CALENDAR: (state, items) => {
      state.calendar = items.map(item => Object.freeze(item))
    },
    ADD_DATE: (state, item) => {
      state.calendar.splice(state.calendar.length, 0, Object.freeze(item))
    },
    REMOVE_DATE: (state, id) => {
      let idx = state.calendar.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.calendar.splice(idx, 1)
      }
    },
    UPDATE_DATE: (state, { eventDate, content }) => {
      if (state.calendar.some(c => c.day === eventDate)) {
        let idx = state.calendar.findIndex(c => c.day === eventDate)
        if (idx !== -1) {
          let newItem = Object.assign({}, state.calendar[idx], content)
          state.calendar.splice(idx, 1, Object.freeze(newItem))
        }
      } else {
        state.calendar.splice(state.calendar.length, 0, Object.freeze(content))
      }
    }
  },
  actions: {
    getCalendar: ({ commit }) => {
      return CalendarResource.show()
        .then(response => {
          commit('SET_CALENDAR', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    removeDate: ({ dispatch, commit }, id) => {
      return CalendarResource.delete(id)
        .then(() => {
          commit('REMOVE_DATE', id)
        })
        .catch(err => {
          throw err
        })
    },
    updateDate: ({ commit }, { eventDate, content }) => {
      return CalendarResource.update(eventDate, content)
        .then(() => {
          commit('UPDATE_DATE', { eventDate, content })
        })
        .catch(err => {
          throw err
        })
    },
    createDate: ({ dispatch, commit, state }, body) => {
      return CalendarResource.create(body)
        .then(response => {
          commit('ADD_DATE', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    getBlocks: ({ commit }) => {
      return BlockResource.show()
        .then(response => {
          commit('SET_BLOCKS', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    addBlock: ({ commit, dispatch }, body) => {
      return BlockResource.create(body)
        .then(response => {
          // dispatch('getCalendar')
          commit('ADD_BLOCK', response.data)
          commit('UPDATE_CALENDAR_BLOCK', response.data)
        })
        .catch(err => {
          throw err
        })
    }
  }
}
