import HTTP from './http-common'

let CalendarResource = {
  show () {
    return HTTP.get('calendar/')
  },

  withSessions () {
    return HTTP.get('calendar/withSessions/')
  },

  update (eventDate, content) {
    return HTTP.put('calendar/' + eventDate, content)
  },

  create (body) {
    return HTTP.post('calendar/', body)
  },

  delete (id) {
    return HTTP.delete('calendar/' + id)
  }
}

export default CalendarResource
