import UserResource from '../../api/user.service'

export default {
  state: {
    users: [],
    roles: ['admin', 'user']
  },
  getters: {},
  mutations: {
    SET_USERS: (state, items) => {
      state.users = items.map(item => Object.freeze(item))
    },
    ADD_USER: (state, item) => {
      state.users.splice(state.users.length, 0, Object.freeze(item))
    },
    EDIT_USER: (state, { id, content }) => {
      let idx = state.users.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.users[idx], content)
        state.users.splice(idx, 1, Object.freeze(newItem))
      }
    },
    CLEAR_USERS: (state) => {
      state.users = []
    },
    REMOVE_USER: (state, id) => {
      let idx = state.users.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.users.splice(idx, 1)
      }
    }
  },
  actions: {
    getUsers: ({ commit }) => {
      return UserResource.show()
        .then(response => {
          commit('SET_USERS', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    createUser: ({ commit }, body) => {
      return UserResource.create(body)
        .then(response => {
          commit('ADD_USER', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    editUser: ({ commit }, { id, content }) => {
      return UserResource.update(id, content)
        .then(() => {
          commit('EDIT_USER', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removeUser: ({ dispatch, commit }, id) => {
      return UserResource.delete(id)
        .then(() => {
          commit('REMOVE_USER', id)
        })
        .catch(err => {
          throw err
        })
    }
  }
}
