import TicketResource from '../../api/ticket.service'

export default {
  state: {
    tickets: [],
    capacity: []
  },
  getters: {
    getTicketsBySessionIdx: (state) => state.tickets.reduce((out, item) => {
      item.sessionIds.forEach(idx => {
        let day = item.day.slice(0, 10)
        out[day + '-' + idx] = out[day + '-' + idx] || []
        out[day + '-' + idx] = out[day + '-' + idx].concat(item)
      })
      return out
    }, {})
  },
  mutations: {
    SET_TICKETS: (state, items) => {
      let itemsIds = state.tickets.map(t => t._id)
      items.forEach(t => {
        if (!itemsIds.includes(t._id)) {
          // state.tickets.push(t)
          state.tickets.splice(state.tickets.length, 0, Object.freeze(t))
        }
      })
    },
    SET_CAPACITY: (state, items) => {
      // state.capacity = item
      state.capacity = items.map(item => Object.freeze(item))
      // items.forEach(i => {
      //   let idx = state.capacity.findIndex(c => i.day === c.day && i.session === c.session)
      //   if (idx !== -1) {
      //     state.capacity.splice(idx, 1, Object.freeze(i))
      //   } else {
      //     state.capacity.splice(state.capacity.length, 0, Object.freeze(i))
      //   }
      // })
    },
    ADD_TICKET: (state, item) => {
      state.tickets.splice(state.tickets.length, 0, Object.freeze(item))
      // state.tickets.push(item)
    },
    ADD_TICKETS: (state, tickets) => {
      tickets.forEach(i => {
        let idx = state.tickets.findIndex(ii => ii.uid === i.uid)
        if (idx !== -1) {
          // state.tickets.splice(idx, 1, i)
          state.tickets.splice(idx, 1, Object.freeze(i))
        } else {
          state.tickets.splice(state.tickets.length, 0, Object.freeze(i))
          // state.tickets.push(i)
        }
      })
    },
    EDIT_TICKET: (state, { id, content }) => {
      // state.tickets.forEach(type => {
      //   if (type._id === id) {
      //     Object.assign(type, content)
      //   }
      // })
      let idx = state.tickets.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.tickets[idx], content)
        state.tickets.splice(idx, 1, Object.freeze(newItem))
      }
    },
    REMOVE_TICKET: (state, id) => {
      let idx = state.tickets.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.tickets.splice(idx, 1)
      }
      // state.tickets = state.tickets.filter(item => item._id !== id)
    }
  },
  actions: {
    // getLastTicketDate: ({ commit, dispatch, state }) => {
    //   return TicketResource.last()
    //     .then(response => {
    //       let last = state.tickets.length > 0 ? state.tickets[state.tickets.length - 1] : { saleDate: new Date(0), uid: 0 }
    //       if (!response.data.hasOwnProperty('saleDate') || response.data.saleDate !== last.saleDate) {
    //         let promise = (last) => new Promise((resolve, reject) => {
    //           dispatch('getTickets')
    //             .then(response => {
    //               resolve(response)
    //             }).catch(error => {
    //               reject(error)
    //             })
    //         })
    //         return promise(last)
    //       } else {
    //         return []
    //       }
    //     })
    //     .catch(err => {
    //       throw err
    //     })
    // },
    getTickets: ({ commit, dispatch }) => {
      return TicketResource.show()
        .then(response => {
          commit('SET_TICKETS', response.data)
        })
        .then(() => {
          let day = new Date().toISOString().slice(0, 10)
          // day.setHours(day.getHours() - 6)
          // let options = { year: 'numeric', month: '2-digit', day: '2-digit' }
          // let date = day.toLocaleString('fr-CA', options)
          dispatch('getCapacity', day)
        })
        .catch(err => {
          throw err
        })
    },
    getTicketsByDate: ({ commit, dispatch }, date) => {
      return TicketResource.showByDate(date)
        .then(response => {
          commit('ADD_TICKETS', response.data)
        })
        .then(() => {
          dispatch('getCapacity', date)
        })
        .catch(err => {
          throw err
        })
    },
    getTicketsByRange: ({ commit }, range) => {
      return TicketResource.showByRange(range)
        .then(response => {
          commit('ADD_TICKETS', response.data)
          return response.data
        })
        .catch(err => {
          throw err
        })
    },
    getCapacity: ({ commit }, date) => {
      return TicketResource.capacity(date)
        .then(response => {
          commit('SET_CAPACITY', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    getCapacityMonth: ({ commit }) => {
      return TicketResource.capacityMonth()
        .then(response => {
          commit('SET_CAPACITY', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    getCapacityPast: ({ commit }) => {
      return TicketResource.capacityPast()
        .then(response => {
          commit('SET_CAPACITY', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    ss_newTicket: ({ commit }, data) => {
      return commit('SET_CAPACITY', data)
    },
    addTicket: ({ commit, dispatch }, body) => {
      return TicketResource.create(body)
        .then(response => {
          commit('ADD_TICKET', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    refundTicket: ({ commit }, id) => {
      return TicketResource.refund(id)
        .then(response => {
        })
        .catch(err => {
          throw err
        })
    },
    editTicket: ({ commit }, { id, content }) => {
      return TicketResource.update(id, content)
        .then(() => {
          commit('EDIT_TICKET', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removeTicket: ({ dispatch, commit }, id) => {
      return TicketResource.delete(id)
        .then(() => {
          commit('REMOVE_TICKET', id)
        })
        .catch(err => {
          throw err
        })
    },

    createBasketItemTicketDefault: ({ dispatch, getters, rootGetters }, { sessionIdx }) => {
      let company = rootGetters['getCompanyByCategory']['Tickets']
      let type = rootGetters['defaultType']
      let session = rootGetters['todaySessions'][sessionIdx]
      dispatch('createBasketItem', {
        category: 'Tickets',
        name: 'Tickets',
        desc: [],
        typeName: type.name,
        typeId: type._id,
        typeIdx: type.idx,
        sessionName: session.name,
        sessionTime: session.time,
        sessionIdx: sessionIdx,
        sessionIdx2: -1,
        day: rootGetters['today'],
        times: 1,
        rate: session.types[type.idx].price,
        clientIds: [],
        clientNames: [],
        isBackToBack: false,
        isDoubleSession: false,
        isBooking: false,
        discountType: '',
        discountPercent: 0,
        discountMemo: '',
        customerId: '',
        customerName: '',
        companyId: company._id,
        companyName: company.name
      })
    }
  }
}
