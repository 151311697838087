import PromoResource from '../../api/promo.service'

export default {
  state: {
    promocodes: []
  },
  getters: {},
  mutations: {
    SET_PROMOS: (state, items) => {
      state.promocodes = items.map(item => Object.freeze(item))
      // state.promocodes = promo
    },
    ADD_PROMO: (state, item) => {
      state.promocodes.splice(state.promocodes.length, 0, Object.freeze(item))
      // state.promocodes.push(promo)
    },
    EDIT_PROMO: (state, { id, content }) => {
      // state.promocodes.forEach(type => {
      //   if (type._id === id) {
      //     Object.assign(type, content)
      //   }
      // })
      let idx = state.promocodes.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.promocodes[idx], content)
        state.promocodes.splice(idx, 1, Object.freeze(newItem))
      }
    },
    REMOVE_PROMO: (state, id) => {
      let idx = state.promocodes.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.promocodes.splice(idx, 1)
      }
      // state.promocodes = state.promocodes.filter(item => item._id !== id)
    }
  },
  actions: {
    getPromos: ({ commit }) => {
      return PromoResource.show()
        .then(response => {
          commit('SET_PROMOS', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    addPromo: ({ commit }, body) => {
      return PromoResource.create(body)
        .then(response => {
          commit('ADD_PROMO', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    editPromo: ({ commit }, { id, content }) => {
      return PromoResource.update(id, content)
        .then(() => {
          commit('EDIT_PROMO', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removePromo: ({ commit }, id) => {
      return PromoResource.delete(id)
        .then(() => {
          commit('REMOVE_PROMO', id)
        })
        .catch(err => {
          throw err
        })
    }
  }
}
