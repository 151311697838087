import MemberResource from '../../api/member.service'

export default {
  state: {
    members: [],
    lastMembers: []
  },
  getters: {
    members: state => state.members,
    customersForSelect: state => {
      return state.members.map(m => {
        return {
          value: m._id, label: m.first + ' ' + m.surname
        }
      })
    },
    lastMembersIds: state => {
      return state.lastMembers.map(m => m._id)
    },
    getMemberById: (state) => state.members.reduce((out, item) => {
      out[item._id] = item
      return out
    }, {}),
    getMemberFullNameById: (state) => state.members.reduce((out, item) => {
      out[item._id] = item.fullName
      return out
    }, {}),
    getMemberByUid: state => id => {
      return state.members.find(m => m.uid.toString() === id.toString())
    },
    getMemberFullNameByUid: getters => id => {
      let m = getters.members.find(m => m.uid.toString() === id.toString())
      return m !== undefined ? m.first + ' ' + m.surname : ''
    },
    getMemberIDByUid: (state, getters) => uid => {
      let member = getters.getMemberByUid(uid)
      return member !== undefined ? member._id : ''
    }
  },
  mutations: {
    SET_MEMBERS: (state, items) => {
      items.forEach(item => {
        state.members.splice(state.members.length, 0, Object.freeze(item))
        if (item.hasOwnProperty('children') && item.children.length > 0) {
          item.children.filter(c => c.isActive).forEach((c, index) => {
            state.members.splice(state.members.length, 0, Object.freeze({
              first: c.first,
              surname: c.hasOwnProperty('surname') ? c.surname : '',
              fullName: c.hasOwnProperty('surname') ? c.first + ' ' + c.surname : c.first,
              phone: item.phone,
              email: item.email,
              birthday: c.birthday,
              isChild: true,
              parentId: item._id,
              parentName: item.first + ' ' + item.surname,
              lesson: 0,
              subscription: 0,
              isActive: c.isActive,
              _id: c._id,
              uid: item.uid + '-' + index
            }))
          })
        }
      })
    },
    ADD_MEMBER: (state, item) => {
      state.members.splice(state.members.length, 0, Object.freeze(item))
      if (item.hasOwnProperty('children') && item.children.length > 0) {
        item.children.filter(c => c.isActive).forEach((c, index) => {
          state.members.splice(state.members.length, 0, Object.freeze({
            first: c.first,
            surname: c.hasOwnProperty('surname') ? c.surname : '',
            fullName: c.hasOwnProperty('surname') ? c.first + ' ' + c.surname : c.first,
            phone: item.phone,
            email: item.email,
            birthday: c.birthday,
            isChild: true,
            parentId: item._id,
            parentName: item.first + ' ' + item.surname,
            lesson: 0,
            subscription: 0,
            isActive: c.isActive,
            _id: c._id,
            uid: item.uid + '-' + index
          }))
        })
      }
      state.lastMembers.splice(state.lastMembers.length, 0, Object.freeze(item))
    },
    EDIT_MEMBER: (state, { id, content }) => {
      let idx = state.members.findIndex(m => m._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.members[idx], content)
        state.members.splice(idx, 1, Object.freeze(newItem))
        state.members = state.members.filter(m => !m.hasOwnProperty('parentId') || m.parentId !== id)
        if (content.hasOwnProperty('cildren') && content.children.length > 0) {
          content.children.filter(c => c.isActive).forEach((c, index) => {
            state.members.splice(idx + 1 + index, 0, Object.freeze({
              first: c.first,
              surname: c.hasOwnProperty('surname') ? c.surname : '',
              fullName: c.hasOwnProperty('surname') ? c.first + ' ' + c.surname : c.first,
              phone: content.phone,
              email: content.email,
              birthday: c.birthday,
              isChild: true,
              parentId: content._id,
              parentName: content.first + ' ' + content.surname,
              lesson: 0,
              subscription: 0,
              isActive: c.isActive,
              _id: c._id,
              uid: content.uid + '-' + index
            }))
          })
        }
      }
    },
    REMOVE_MEMBER: (state, id) => {
      let idx = state.members.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.members.splice(idx, 1)
      }
    },
    CLEAR_LAST_MEMBERS: (state) => {
      state.lastMembers = state.lastMembers.filter(m => new Date(m.dateReg).getTime() + (1000 * 60 * 2) > new Date().getTime())
    },
    EDIT_MEMBER_PROPERTY: (state, { id, property, count }) => {
      state.members.forEach((m, index) => {
        if (m._id === id) {
          let newItem
          if (m.hasOwnProperty(property)) {
            newItem = Object.assign({}, m, {
              [property]: m[property] + count
            })
          } else {
            newItem = Object.assign({}, m, {
              [property]: count
            })
          }
          state.members.splice(index, 1, Object.freeze(newItem))
        }
      })
    }
  },
  actions: {
    clearLastMembers: ({ commit }) => {
      commit('CLEAR_LAST_MEMBERS')
    },
    ss_newMemberTransactiont: ({ commit }, { id, property, count }) => {
      commit('EDIT_MEMBER_PROPERTY', { id, property, count })
    },
    getMembers: ({ commit, dispatch }) => {
      return MemberResource.show()
        .then(response => {
          commit('SET_MEMBERS', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    addClient: ({ commit }, body) => {
      return MemberResource.create(body)
        .then(response => {
          commit('ADD_MEMBER', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    ss_newCustomer: ({ commit, getters }, body) => {
      let customer = getters.getMemberById[body._id]
      if (customer) {
        commit('EDIT_MEMBER', { id: body._id, content: body })
      } else {
        return commit('ADD_MEMBER', body)
      }
    },
    editMember: ({ commit }, { id, content }) => {
      return MemberResource.update(id, content)
        .then(response => {
          commit('EDIT_MEMBER', { id, content: response.data[0] })
        })
        .catch(err => {
          throw err
        })
    },
    ss_editCustomer: ({ commit }, { id, content }) => {
      return commit('EDIT_MEMBER', { id, content })
    },
    removeMember: ({ dispatch, commit }, id) => {
      return MemberResource.delete(id)
        .then(() => {
          commit('REMOVE_MEMBER', id)
        })
        .catch(err => {
          throw err
        })
    }
  }
}
