import EventResource from '../../api/event.service'

export default {
  state: {
    events: []
  },
  getters: {
    // getEventById: state => id => {
    //   let event = state.events.find(c => c.uid === id)
    //   return event !== undefined ? event : false
    // }
    getEventById: (state) => state.events.reduce((out, item) => {
      out[item.uid] = item
      return out
    }, {})
  },
  mutations: {
    SET_EVENTS: (state, items) => {
      // state.events = event
      state.events = items.map(item => Object.freeze(item))
    },
    ADD_EVENT: (state, item) => {
      // state.events.push(event)
      state.events.splice(state.events.length, 0, Object.freeze(item))
    },
    EDIT_EVENT: (state, { id, content }) => {
      // state.events.forEach(event => {
      //   if (event.uid === id) {
      //     Object.assign(event, content)
      //   }
      // })
      let idx = state.events.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.events[idx], content)
        state.events.splice(idx, 1, Object.freeze(newItem))
      }
    },
    REMOVE_EVENT: (state, id) => {
      let idx = state.events.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.events.splice(idx, 1)
      }
      // state.events = state.events.filter(item => item._id !== id)
    }
  },
  actions: {
    getEvents: ({ commit }) => {
      return EventResource.show()
        .then(response => {
          commit('SET_EVENTS', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    addEvent: ({ commit }, body) => {
      return EventResource.create(body)
        .then(response => {
          commit('ADD_EVENT', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    editEvent: ({ commit }, { id, content }) => {
      return EventResource.update(id, content)
        .then(() => {
          commit('EDIT_EVENT', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removeEvent: ({ dispatch, commit }, id) => {
      return EventResource.delete(id)
        .then(() => {
          commit('REMOVE_EVENT', id)
        })
        .then(() => {
          return dispatch('getEvents')
        })
        .catch(err => {
          throw err
        })
    }
  }
}
