import store from '../../store'

export default (to, from, next) => {
  const categories = store.state.category.categories || []
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      if (categories.length === 0) {
        return store.dispatch('getCategories')
      }
    })
    .finally(() => {
      next()
    })
}
