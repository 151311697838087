import Vue from 'vue'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import store from '../store'

const socket = io(process.env.VUE_APP_SOCKET_URL, {
  path: '/stream2'
})

Vue.use(VueSocketIOExt, socket, { store })
