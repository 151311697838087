import HTTP from './http-common'

let TicketResource = {
  show () {
    return HTTP.get('ticket/')
  },

  showByDate (date) {
    return HTTP.get('ticket/date/' + date)
  },

  showByRange (date) {
    return HTTP.get('ticket/range/' + date)
  },

  capacity (date) {
    return HTTP.get('ticket/capacity/' + date)
  },

  capacityMonth (date) {
    return HTTP.get('ticket/capacityMonth/')
  },

  capacityPast (date) {
    return HTTP.get('ticket/capacityPast/')
  },

  refund (id) {
    return HTTP.put('ticket/refund/' + id)
  },

  update (id, body) {
    return HTTP.put('ticket/' + id, body)
  },

  create (body) {
    return HTTP.post('ticket/', body)
  },

  delete (id) {
    return HTTP.delete('ticket/' + id)
  },

  last () {
    return HTTP.get('ticket/last/')
  }
}

export default TicketResource
