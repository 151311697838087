import HTTP from './http-common'

let TransactionResource = {
  show () {
    return HTTP.get('transaction')
  },

  get (id) {
    return HTTP.get('transaction/' + id)
  },

  sum () {
    return HTTP.get('transaction/sum')
  },

  create (body) {
    return HTTP.post('transaction', body)
  }
}

export default TransactionResource
