import beforeEnterUsers from './hooks/beforeEnterUsers'
import beforeEnterCalendar from './hooks/beforeEnterCalendar'
import beforeEnterWarehouse from './hooks/beforeEnterWarehouse'
import beforeEnterGroups from './hooks/beforeEnterGroups'
import beforeEnterClients from './hooks/beforeEnterClients'
import beforeEnterInvoices from './hooks/beforeEnterInvoices'
import beforeEnterInvoice from './hooks/beforeEnterInvoice'
import beforeEnterTypes from './hooks/beforeEnterTypes'
import beforeEnterCategories from './hooks/beforeEnterCategories'
import beforeEnterCompanies from './hooks/beforeEnterCompanies'
import beforeEnterLessons from './hooks/beforeEnterLessons'
import beforeEnterEvents from './hooks/beforeEnterEvents'
import beforeEnterSubscriptions from './hooks/beforeEnterSubscriptions'
import beforeEnterHome from './hooks/beforeEnterHome'
import beforeEnterPromo from './hooks/beforeEnterPromo'
import beforeEnterBooks from './hooks/beforeEnterBooks'
import beforeEnterBlockSession from './hooks/beforeEnterBlockSession'
// import beforeEnterCapacity from './hooks/beforeEnterCapacity'
import beforeEnterCapacityReport from './hooks/beforeEnterCapacityReport'

export default [
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "calendar" */ '../views/admin/calendar/Calendar.vue'),
    beforeEnter: beforeEnterCalendar,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import(/* webpackChunkName: "templates" */ '../views/admin/calendar/Templates.vue'),
    beforeEnter: beforeEnterCalendar,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/sessions',
    name: 'sessions',
    component: () => import(/* webpackChunkName: "sessions" */ '../views/admin/calendar/Sessions.vue'),
    beforeEnter: beforeEnterCalendar,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/admin/Staff.vue'),
    beforeEnter: beforeEnterUsers,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/admin/Buttons.vue'),
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/types',
    name: 'types',
    beforeEnter: beforeEnterTypes,
    component: () => import(/* webpackChunkName: "types" */ '../views/admin/calendar/Types.vue'),
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/category',
    name: 'category',
    beforeEnter: beforeEnterCategories,
    component: () => import(/* webpackChunkName: "category" */ '../views/admin/settings/Category.vue'),
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/company',
    name: 'company',
    beforeEnter: beforeEnterCompanies,
    component: () => import(/* webpackChunkName: "company" */ '../views/admin/settings/Company.vue'),
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/lesson',
    name: 'lesson',
    beforeEnter: beforeEnterLessons,
    component: () => import(/* webpackChunkName: "lesson" */ '../views/admin/settings/Lesson.vue'),
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/event',
    name: 'event',
    beforeEnter: beforeEnterEvents,
    component: () => import(/* webpackChunkName: "event" */ '../views/admin/settings/Event.vue'),
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    component: () => import(/* webpackChunkName: "warehouse" */ '../views/admin/warehouse/Warehouse.vue'),
    beforeEnter: beforeEnterWarehouse,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import(/* webpackChunkName: "inventory" */ '../views/admin/warehouse/Inventory.vue'),
    beforeEnter: beforeEnterWarehouse,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: () => import(/* webpackChunkName: "purchase" */ '../views/admin/warehouse/Purchase.vue'),
    beforeEnter: beforeEnterWarehouse,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/createItem',
    name: 'createItem',
    component: () => import(/* webpackChunkName: "createItem" */ '../views/admin/warehouse/CreateItem.vue'),
    beforeEnter: beforeEnterWarehouse,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/editItem/:id',
    name: 'editItem',
    component: () => import(/* webpackChunkName: "editItem" */ '../views/admin/warehouse/EditItem.vue'),
    beforeEnter: beforeEnterWarehouse,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/transaction/:id?',
    name: 'transaction',
    component: () => import(/* webpackChunkName: "transaction" */ '../views/admin/warehouse/Transaction.vue'),
    beforeEnter: beforeEnterWarehouse,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/double',
    name: 'double',
    component: () => import(/* webpackChunkName: "double" */ '../views/admin/settings/Double.vue'),
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/groups',
    name: 'groups',
    beforeEnter: beforeEnterGroups,
    component: () => import(/* webpackChunkName: "groups" */ '../views/admin/settings/Groups.vue'),
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/clients',
    name: 'clients',
    beforeEnter: beforeEnterClients,
    component: () => import(/* webpackChunkName: "clients" */ '../views/admin/repors/Customers.vue'),
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/editClient/:id',
    name: 'editClient',
    component: () => import(/* webpackChunkName: "editClient" */ '../views/admin/settings/EditClient.vue'),
    beforeEnter: beforeEnterWarehouse,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/subscription',
    name: 'subscription',
    beforeEnter: beforeEnterSubscriptions,
    component: () => import(/* webpackChunkName: "subscription" */ '../views/admin/settings/Subscription.vue'),
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import(/* webpackChunkName: "invoices" */ '../views/admin/repors/Invoices.vue'),
    beforeEnter: beforeEnterInvoices,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/invoice/:id',
    name: 'invoice',
    component: () => import(/* webpackChunkName: "invoice" */ '../views/admin/repors/Invoice.vue'),
    beforeEnter: beforeEnterInvoice,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/ticketsReport',
    name: 'ticketsReport',
    component: () => import(/* webpackChunkName: "ticketsReport" */ '../views/admin/repors/TicketsReport.vue'),
    beforeEnter: beforeEnterHome,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/capacityReport',
    name: 'capacityReport',
    component: () => import(/* webpackChunkName: "capacityReport" */ '../views/admin/repors/CapacityReport.vue'),
    beforeEnter: beforeEnterCapacityReport,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/booksReport',
    name: 'booksReport',
    component: () => import(/* webpackChunkName: "booksReport" */ '../views/admin/repors/BooksReport.vue'),
    beforeEnter: beforeEnterBooks,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/editBook/:id',
    name: 'editBook',
    component: () => import(/* webpackChunkName: "editBook" */ '../views/admin/settings/EditBook.vue'),
    beforeEnter: beforeEnterHome,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },

  {
    path: '/promocodes',
    name: 'promocodes',
    component: () => import(/* webpackChunkName: "promocodes" */ '../views/admin/settings/Promo.vue'),
    beforeEnter: beforeEnterPromo,
    meta: {
      auth: true,
      roles: ['admin']
    }
  },
  {
    path: '/blockSession',
    name: 'blockSession',
    component: () => import(/* webpackChunkName: "blockSession" */ '../views/admin/settings/BlockSession.vue'),
    beforeEnter: beforeEnterBlockSession,
    meta: {
      auth: true,
      roles: ['admin']
    }
  }
]
