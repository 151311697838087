import HTTP from './http-common'

let TypeResource = {
  show () {
    return HTTP.get('setting/types')
  },

  update (id, body) {
    return HTTP.put('setting/types/' + id, body)
  },

  create (body) {
    return HTTP.post('setting/types', body)
  },

  delete (id) {
    return HTTP.delete('setting/types/' + id)
  }
}

export default TypeResource
