import HTTP from './http-common'

let MemberTransactionResource = {
  show () {
    return HTTP.get('memberTransaction')
  },

  get (id) {
    return HTTP.get('memberTransaction/' + id)
  },

  sum () {
    return HTTP.get('memberTransaction/sum')
  },

  create (body) {
    return HTTP.post('memberTransaction', body)
  }
}

export default MemberTransactionResource
