import WarehouseResource from '../../api/warehouse.service'

export default {
  state: {
    warehouse: []
  },
  getters: {
    items (state) {
      return state.warehouse
    },
    addons (state, getters, rootState) {
      return state.warehouse.filter(t => t.category === getters.addonsCategoryId)
    },
    snacks (state, getters, rootState) {
      return state.warehouse.filter(t => t.category === getters.snacksCategoryId)
    },
    merch (state, getters, rootState) {
      return state.warehouse.filter(t => t.category === getters.merchCategoryId)
    },
    getItemByCode: (state) => state.warehouse.reduce((out, item) => {
      out[item.barcode] = item
      return out
    }, {}),
    // getItemByUid: (state) => state.warehouse.reduce((out, item) => {
    //   out[item.uid] = item
    //   return out
    // }, {}),
    getItemById: (state) => state.warehouse.reduce((out, item) => {
      out[item._id] = item
      return out
    }, {}),
    // getItemCompanyById: (state, getters, rootState) => id => {
    //   let item = state.warehouse.find(item => item.uid === id)
    //   let company
    //   if (item !== undefined) {
    //     company = rootState.company.companies.find(c => c._id === item.company)
    //     if (company === undefined) {
    //       company = { _id: -1, name: '' }
    //     }
    //   } else {
    //     company = { _id: -1, name: '' }
    //   }
    //   return company
    // },
    getItemCompanyByUid: (state, getters, rootState) => state.warehouse.reduce((out, item) => {
      out[item.uid] = rootState.company.companies.find(c => c._id === item.company) || { _id: -1, name: '' }
      return out
    }, {})
  },
  mutations: {
    SET_WAREHOUSE: (state, payload) => {
      state.warehouse = payload.map(item => Object.freeze(item))
    },
    ADD_ITEM: (state, item) => {
      state.warehouse.splice(state.warehouse.length, 0, Object.freeze(item))
    },
    EDIT_ITEM: (state, { id, content }) => {
      let idx = state.warehouse.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.warehouse[idx], content)
        state.warehouse.splice(idx, 1, Object.freeze(newItem))
      }
    },
    REMOVE_ITEM: (state, id) => {
      let idx = state.warehouse.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.warehouse.splice(idx, 1)
      }
    },
    EDIT_ITEM_COUNT: (state, { itemId, count }) => {
      let idx = state.warehouse.findIndex(l => l._id === itemId)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.warehouse[idx], { count: state.warehouse[idx].hasOwnProperty('count') ? state.warehouse[idx].count + count : count })
        state.warehouse.splice(idx, 1, Object.freeze(newItem))
      }

      // state.warehouse.forEach(i => {
      //   if (i.uid === itemId) {
      //     if (i.hasOwnProperty('count')) {
      //       i.count = i.count + count
      //     } else {
      //       i.count = count
      //     }
      //   }
      // })
    }
  },
  actions: {
    ss_newTransactiont: ({ commit }, data) => {
      commit('EDIT_ITEM_COUNT', { itemId: data.itemId, count: data.count })
      commit('PUSH_GROUPED', {
        id: data.itemId,
        transaction: data
      })
    },
    getWarehouse: ({ commit, dispatch }) => {
      return WarehouseResource.show()
        .then(response => {
          commit('SET_WAREHOUSE', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    addItem: ({ commit }, body) => {
      return WarehouseResource.create(body)
        .then(response => {
          commit('ADD_ITEM', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    editItem: ({ commit }, { id, content }) => {
      return WarehouseResource.update(id, content)
        .then(() => {
          commit('EDIT_ITEM', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removeItem: ({ dispatch, commit }, id) => {
      return WarehouseResource.delete(id)
        .then(() => {
          commit('REMOVE_ITEM', id)
        })
        .catch(err => {
          throw err
        })
    },

    createBasketItemSnackDefault: ({ dispatch, getters, rootGetters }, { item, times, category }) => {
      let company = rootGetters['getCompanyById'][item.company]
      dispatch('createBasketItem', {
        category: category,
        itemId: item._id,
        name: item.name,
        desc: [item.description],
        times,
        rate: item.cost,
        companyId: company._id,
        companyName: company.name
      })
    }
  }
}
