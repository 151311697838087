import CompanyResource from '../../api/company.service'

export default {
  state: {
    companies: []
  },
  getters: {
    companiesForSelect: (state, getters) => {
      return state.companies
        .map(el => {
          return {
            value: el._id,
            label: el.name
          }
        })
    },
    companiesForFilter: (state, getters) => {
      return state.companies
        .map(el => {
          return {
            value: el.name,
            text: el.name
          }
        })
    },
    getCompanyNameById: (state) => state.companies.reduce((out, item) => {
      out[item._id] = item.name
      return out
    }, {}),
    getCompanyIdByName: (state) => state.companies.reduce((out, item) => {
      out[item.name] = item._id
      return out
    }, {}),
    getCompanyById: (state) => state.companies.reduce((out, item) => {
      out[item._id] = item
      return out
    }, {}),
    getCompanyByCategory: (state) => state.companies.reduce((out, item) => {
      item.category.forEach(i => {
        out[i] = item
      })
      return out
    }, {})
  },
  mutations: {
    SET_COMPANIES: (state, items) => {
      state.companies = items.map(item => Object.freeze(item))
    },
    ADD_COMPANY: (state, item) => {
      state.companies.splice(state.companies.length, 0, Object.freeze(item))
    },
    EDIT_COMPANY: (state, { id, content }) => {
      state.companies.forEach(type => {
        if (type._id === id) {
          Object.assign(type, content, {
            category: [...content.category]
          })
        }
      })

      let idx = state.companies.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.companies[idx], content, {
          category: [...state.companies[idx].category]
        })
        state.companies.splice(idx, 1, Object.freeze(newItem))
      }
    },
    REMOVE_COMPANY: (state, id) => {
      let idx = state.companies.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.companies.splice(idx, 1)
      }
    }
  },
  actions: {
    getCompanies: ({ commit }) => {
      return CompanyResource.show()
        .then(response => {
          commit('SET_COMPANIES', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    addCompany: ({ commit }, body) => {
      return CompanyResource.create(body)
        .then(response => {
          commit('ADD_COMPANY', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    editCompany: ({ commit }, { id, content }) => {
      return CompanyResource.update(id, content)
        .then(() => {
          commit('EDIT_COMPANY', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removeCompany: ({ commit }, id) => {
      return CompanyResource.delete(id)
        .then(() => {
          commit('REMOVE_COMPANY', id)
        })
        .catch(err => {
          throw err
        })
    }
  }
}
