import store from '../../store'

export default (to, from, next) => {
  const events = store.state.event.events || []
  const clients = store.state.members.members || []
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      if (events.length === 0) {
        return store.dispatch('getEvents')
      }
    })
    .then(() => {
      if (clients.length === 0) {
        return store.dispatch('getMembers')
      }
    })
    .finally(() => {
      next()
    })
}
