import store from '../../store'

export default (to, from, next) => {
  if (!to.meta.auth) {
    return next()
  }

  if (!store.getters.isAuthenticated) {
    return next('/login')
  }

  if (!to.meta.roles) {
    return next()
  }

  if (to.meta.roles.includes(store.getters.getCurrentUser.role)) {
    return next()
  }

  next('/')
}
