import HTTP from './http-common'

let SubscriptionResource = {
  show () { // Show all the users
    return HTTP.get('subscription/')
  },

  update (id, body) {
    return HTTP.put('subscription/' + id, body)
  },

  create (body) {
    return HTTP.post('subscription/', body)
  },

  delete (id) {
    return HTTP.delete('subscription/' + id)
  }
}

export default SubscriptionResource
