export default {
  state: {
    innerHeight: 0,
    innerWidth: 0,
    menu: [],
    layout: {
      navPos: 'left', // top, bottom, left, right, false
      toolbar: 'true', // top, bottom, false
      footer: 'below', // above, below, false
      boxed: false, // true, false
      roundedCorners: false, // true, false
      viewAnimation: 'fade-top' // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
    },
    splashScreen: true,
    logged: true,
    loading: true
  },
  getters: {
    layout (state, getters) {
      return state.layout
    },
    navPos (state, getters) {
      return state.layout.navPos
    },
    toolbar (state, getters) {
      return state.layout.toolbar
    },
    footer (state, getters) {
      return state.layout.footer
    },
    boxed (state, getters) {
      return state.layout.boxed
    },
    roundedCorners (state, getters) {
      return state.layout.roundedCorners
    },
    viewAnimation (state, getters) {
      return state.layout.viewAnimation
    },
    isLogged (state, getters) {
      return state.logged
    },
    splashScreen (state, getters) {
      return state.splashScreen
    }
  },
  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload
    },
    SET_INNER_SIZE: (state, payload) => {
      state.innerHeight = payload.innerHeight
      state.innerWidth = payload.innerWidth
    }
  },
  actions: {
    setLoading: ({ commit }, payload) => {
      commit('SET_LOADING', payload)
    },
    setInnerSize: ({ commit }, payload) => {
      commit('SET_INNER_SIZE', payload)
    }
  }
}
