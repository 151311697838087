import TransactionResource from '../../api/transaction.service'

export default {
  state: {
    grouped: []
  },

  getters: {
    grouped: state => state.grouped
  },

  mutations: {
    PUSH_GROUPED: (state, { id, transaction }) => {
      if (Array.isArray(state.grouped[id])) {
        state.grouped[id].push(transaction)
      } else {
        state.grouped[id] = [transaction]
      }
    },
    SET_GROUPED: (state, { id, transactions }) => {
      state.grouped[id] = transactions
    }
  },

  actions: {
    getTransaction: ({ commit }, { id }) => {
      return TransactionResource.get(id)
        .then(response => {
          commit('SET_GROUPED', { id, transactions: response.data })
        })
        .catch(err => {
          throw err
        })
    },
    addTransaction: ({ commit }, transaction) => {
      return TransactionResource.create(transaction)
        .catch(err => {
          throw err
        })
    }
  }
}
