import store from '../../store'

export default (to, from, next) => {
  const types = store.state.type.types || []
  const categories = store.state.category.categories || []
  const companies = store.state.company.companies || []
  const lessons = store.state.lesson.lessons || []
  const events = store.state.event.events || []
  const warehouse = store.state.event.warehouse || []
  const clients = store.state.members.members || []
  const groups = store.state.group.groups || []
  const subscriptions = store.state.subscription.subscriptions || []
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      if (events.length === 0) {
        return store.dispatch('getEvents')
      }
    })
    .then(() => {
      if (types.length === 0) {
        return store.dispatch('getTypes')
      }
    })
    .then(() => {
      if (categories.length === 0) {
        return store.dispatch('getCategories')
      }
    })
    .then(() => {
      if (companies.length === 0) {
        return store.dispatch('getCompanies')
      }
    })
    .then(() => {
      if (lessons.length === 0) {
        return store.dispatch('getLessons')
      }
    })
    .then(() => {
      if (warehouse.length === 0) {
        return store.dispatch('getWarehouse')
      }
    })
    .then(() => {
      return store.dispatch('getTickets')
    })
    .then(() => {
      return store.dispatch('getCapacity', new Date().toISOString().slice(0, 10))
    })
    .then(() => {
      if (clients.length === 0) {
        return store.dispatch('getMembers')
      }
    })
    .then(() => {
      if (groups.length === 0) {
        return store.dispatch('getGroups')
      }
    })
    .then(() => {
      if (subscriptions.length === 0) {
        return store.dispatch('getSubscriptions')
      }
    })
    .then(() => {
      return store.dispatch('getInvoices')
    })
    .finally(() => {
      next()
    })
}
