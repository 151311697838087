import HTTP from './http-common'

let MemberResource = {
  show () { // Show all the users
    return HTTP.get('member/')
  },

  showDate (uid) { // Show all the users
    return HTTP.get('member/date/' + uid)
  },

  update (id, body) {
    return HTTP.put('member/' + id, body)
  },

  create (body) {
    return HTTP.post('member/', body)
  },

  delete (id) {
    return HTTP.delete('member/' + id)
  },

  last () {
    return HTTP.get('member/last/')
  },

  edited (date) {
    return HTTP.get('member/edited/' + date)
  }
}

export default MemberResource
