import SessionResource from '../../api/session.service'

export default {
  state: {
    sessions: []
  },
  getters: {
    sessionsForSelect: (state) => {
      return state.sessions
        .map(el => {
          return {
            value: el._id,
            label: el.name
          }
        })
    }
  },
  mutations: {
    SET_SESSIONS: (state, items) => {
      state.sessions = items.map(item => Object.freeze(item))
      // state.sessions = sessions
    },
    ADD_SESSION: (state, item) => {
      // state.sessions.push(session)
      state.sessions.splice(state.sessions.length, 0, Object.freeze(item))
    },
    EDIT_SESSION: (state, { id, content }) => {
      // state.sessions.forEach(type => {
      //   if (type._id === id) {
      //     Object.assign(type, content)
      //   }
      // })
      let idx = state.sessions.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.sessions[idx], content)
        state.sessions.splice(idx, 1, Object.freeze(newItem))
      }
    },
    REMOVE_SESSION: (state, id) => {
      // state.sessions = state.sessions.filter(item => item._id !== id)
      let idx = state.sessions.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.sessions.splice(idx, 1)
      }
    }
  },
  actions: {
    getSessions: ({ commit }) => {
      return SessionResource.show()
        .then(response => {
          commit('SET_SESSIONS', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    createSession: ({ commit }, body) => {
      return SessionResource.create(body)
        .then(response => {
          commit('ADD_SESSION', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    editSession: ({ commit }, { id, content }) => {
      return SessionResource.update(id, content)
        .then(() => {
          commit('EDIT_SESSION', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removeSession: ({ commit }, id) => {
      return SessionResource.delete(id)
        .then(() => {
          commit('REMOVE_SESSION', id)
        })
        .catch(err => {
          throw err
        })
    }
  }
}
