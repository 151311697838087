import BookingResource from '../../api/booking.service'

export default {
  state: {
    books: []
  },
  getters: {
    // getBooksFromWaiverIds: (state, getters) => ({ day, sessionIdx, waiversArray }) => {
    //   let waiversIdsArray = waiversArray.map(w => getters.getMemberIDByUid(w))
    //   return state.books.filter(book => {
    //     return book.day === day && book.session.idx === sessionIdx && waiversIdsArray.includes(book.customer.id)
    //   })
    // },
    // getBooksFromCustomerId: state => ({ day, id }) => {
    //   return state.books.filter(book => book.day === day && book.customer.id === id)
    // }
    getBooksFromCustomerId: (state) => state.books.reduce((out, item) => {
      out[item.day + '-' + item.customer.id] = out[item.day + '-' + item.customer.id] || []
      out[item.day + '-' + item.customer.id] = out[item.day + '-' + item.customer.id].concat(item)
      return out
    }, {}),
    getBooksBySessionIdx: (state) => state.books.reduce((out, item) => {
      out[item.day + '-' + item.session.idx] = out[item.day + '-' + item.session.idx] || []
      out[item.day + '-' + item.session.idx] = out[item.day + '-' + item.session.idx].concat(item)
      return out
    }, {}),
    getBookById: (state) => state.books.reduce((out, item) => {
      out[item._id] = item
      return out
    }, {})
  },
  mutations: {
    SET_BOOKS: (state, items) => {
      // let itemsIds = state.books.map(b => b._id)
      // items.forEach(book => {
      //   if (!itemsIds.includes(book._id)) {
      //     state.books.push(book)
      //   }
      // })

      let itemsIds = state.books.map(b => b._id)
      items.forEach(book => {
        if (!itemsIds.includes(book._id)) {
          // state.tickets.push(t)
          state.books.splice(state.books.length, 0, Object.freeze(book))
        }
      })
    },
    ADD_BOOK: (state, item) => {
      // state.books.push(item)
      state.books.splice(state.books.length, 0, Object.freeze(item))
    },
    ADD_BOOKS: (state, books) => {
      books.forEach(i => {
        let idx = state.books.findIndex(ii => ii._id === i._id)
        if (idx !== -1) {
          state.books.splice(idx, 1, Object.freeze(i))
        } else {
          state.books.splice(state.books.length, 0, Object.freeze(i))
        }
      })
    },
    EDIT_BOOK: (state, { id, content }) => {
      let idx = state.books.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.books[idx], content)
        state.books.splice(idx, 1, Object.freeze(newItem))
      }
    },
    REMOVE_BOOK: (state, id) => {
      let idx = state.books.findIndex(l => l._id === id)
      if (idx !== -1) {
        state.books.splice(idx, 1)
      }
    }
  },
  actions: {
    ss_newBook: ({ commit, getters }, body) => {
      let book = getters.getBookById[body._id]
      if (book) {
        commit('EDIT_BOOK', { id: body._id, content: body })
      } else {
        return commit('ADD_BOOK', body)
      }
    },
    ss_editBook: ({ commit }, { id, content }) => {
      return commit('EDIT_BOOK', { id, content })
    },
    getBooks: ({ commit, dispatch }) => {
      return BookingResource.show()
        .then(response => {
          commit('SET_BOOKS', response.data)
        })
        .then(() => {
          // let day = new Date()
          // day.setHours(day.getHours() - 6)
          // let options = { year: 'numeric', month: '2-digit', day: '2-digit' }
          // let date = day.toLocaleString('fr-CA', options)
          // dispatch('getCapacity', date)
        })
        .catch(err => {
          throw err
        })
    },
    getBooksByDate: ({ commit, dispatch }, date) => {
      return BookingResource.showByDate(date)
        .then(response => {
          commit('ADD_BOOKS', response.data)
        })
        .then(() => {
          // dispatch('getCapacity', date)
        })
        .catch(err => {
          throw err
        })
    },
    // ss_newTicket: ({ commit }, data) => {
    //   return commit('SET_CAPACITY', data)
    // },
    addBook: ({ commit, dispatch }, body) => {
      return BookingResource.create(body)
        .then(response => {
          commit('ADD_BOOK', response.data)
        })
        .catch(err => {
          throw err
        })
    },
    // refundBook: ({ commit }, id) => {
    //   return BookingResource.refund(id)
    //     .then(response => {
    //     })
    //     .catch(err => {
    //       throw err
    //     })
    // },
    editBook: ({ commit }, { id, content }) => {
      return BookingResource.update(id, content)
        .then(() => {
          commit('EDIT_BOOK', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    removeBook: ({ dispatch, commit }, id) => {
      return BookingResource.delete(id)
        .then(() => {
          commit('REMOVE_BOOK', id)
        })
        // .then(() => {
        //   return dispatch('getBooks')
        // })
        .catch(err => {
          throw err
        })
    },

    createBasketItemBookDefault: ({ dispatch, rootGetters }, { book }) => {
      let company = rootGetters['getCompanyByCategory']['Books']
      dispatch('createBasketItem', {
        category: 'Books',
        name: 'Books',
        desc: [],
        times: book.ticket.persons,
        rate: 0,
        day: book.day,
        addons: book.addons,
        sessionIdx: book.session.idx,
        sessionTime: book.session.time,
        sessionName: book.session.name,
        typeName: book.ticket.name,
        typeId: book.ticket.idx,
        customerId: book.customer.id,
        customerName: book.customer.first + ' ' + book.customer.surname,
        clientIds: [book.customer.id],
        clientNames: [book.customer.first + ' ' + book.customer.surname],
        bookId: book._id,
        companyId: company._id,
        companyName: company.name
      })
    }
  }
}
