import HTTP from './http-common'

let BookingResource = {
  show () {
    return HTTP.get('booking/')
  },

  showByDate (date) {
    return HTTP.get('booking/date/' + date)
  },

  update (id, body) {
    return HTTP.put('booking/' + id, body)
  },

  create (body) {
    return HTTP.post('booking/', body)
  },

  delete (id) {
    return HTTP.delete('booking/' + id)
  }
}

export default BookingResource
