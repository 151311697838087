import MemberTransactionResource from '../../api/memberTransaction.service'

export default {
  state: {},

  getters: {},

  mutations: {},

  actions: {

    addMemTransaction: ({ commit }, transaction) => {
      return MemberTransactionResource.create(transaction)
        .then(response => {
        })
        .catch(err => {
          throw err
        })
    }
  }
}
