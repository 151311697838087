import store from '../../store'

export default (to, from, next) => {
  const types = store.state.type.types || []
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      if (types.length === 0) {
        return store.dispatch('getTypes')
      }
    })
    .finally(() => {
      next()
    })
}
