import store from '../../store'

export default (to, from, next) => {
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      return store.dispatch('getBooks')
    })
    .finally(() => {
      next()
    })
}
