<template>
  <fullscreen class="layout-container flex justify-center" ref="fullscreen" :fullscreen.sync="fullscreen" :page-only="pageOnly" :exit-on-click-wrapper="exitOnClickWrapper" background="#191d24">
    <div class="container flex column box grow">
      <div class="main box grow flex mv-15">
        <transition name="fade" mode="out-in">
            <router-view
              class="view box grow"/>
        </transition>
      </div>
      <Footer position="below" @fullscreen="toggle" :fullscreen="fullscreen"/>
    </div>

  </fullscreen>
</template>

<script>
import { mapActions } from 'vuex'
import Footer from './core/footer.vue'

export default {
  name: 'app',

  data () {
    return {
      pageOnly: false,
      exitOnClickWrapper: false,
      fullscreen: false
    }
  },

  components: {
    Footer
  },

  methods: {
    ...mapActions(['setInnerSize', 'afterChangeBasket', 'removeBasketTrash']),
    toggle () {
      this.$refs['fullscreen'].toggle()
    },
    fullscreenChange (fullscreen) {
      this.fullscreen = fullscreen
    },
    resizeOpenNav () {
      this.setInnerSize({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth
      })
    }
  },
  mounted () {
    this.resizeOpenNav()
    window.addEventListener('resize', this.resizeOpenNav)

    // автоназначение waivers после actions в которых меняется times
    this.unsubscribe = this.$store.subscribeAction({
      after: (action, state) => {
        if ([
          'createBasketItem',
          'remoteBasketItem'].includes(action.type) ||
        (action.type === 'editBasketItem' &&
          action.payload.hasOwnProperty('content') &&
          action.payload.content.hasOwnProperty('times'))) {
          this.afterChangeBasket()
        }
      }
    })
  },
  beforeDestroy () {
    this.unsubscribe()
    window.removeEventListener('resize', this.resizeOpenNav)
  }
}
</script>

<style lang="scss">
  @import './assets/scss/_variables';
  @import './assets/scss/_mixins';

  .layout-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    background: $background-color;

    .container {
      overflow: hidden;

      .main {
        position: relative;
        overflow: hidden;
        padding: 0 15px;
      }

      .view {
        padding: 0;
        box-sizing: border-box;
        transition: all .1s cubic-bezier(.55,0,.1,1);
        backface-visibility: hidden;

      }
      .fade-top-in-out-enter { opacity: 0; transform: translate(0, 30px); }
      .fade-top-in-out-leave-active { opacity: 0; transform: translate(0, 30px); }

      .fade-top-enter { opacity: 0; transform: translate(0, 30px); }
      .fade-top-leave-active { opacity: 0; transform: translate(0, -30px); }

      .fade-bottom-in-out-enter { opacity: 0; transform: translate(0, -30px); }
      .fade-bottom-in-out-leave-active { opacity: 0; transform: translate(0, -30px); }

      .fade-bottom-enter { opacity: 0; transform: translate(0, -30px); }
      .fade-bottom-leave-active { opacity: 0; transform: translate(0, 30px); }

      .fade-left-enter { opacity: 0; transform: translate(30px, 0); }
      .fade-left-leave-active { opacity: 0; transform: translate(-30px, 0); }

      .fade-right-enter { opacity: 0; transform: translate(-30px, 0); }
      .fade-right-leave-active { opacity: 0; transform: translate(30px, 0); }

      .fade-enter { opacity: 0; }
      .fade-leave-active { opacity: 0; }

    }
  }

  @media (min-width: 1920px) {
    .layout-container:not(.boxed) {
      &.column {
        .container {
          margin: 0 auto;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .layout-container {
      .container {

        .main {
          padding: 0 5px;
        }

        .view {
          padding: 5px;
          max-width: 100%;
          /*padding: 15px;*/
        }
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease-out;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
