import HTTP from './http-common'

let PromoResource = {
  show () { // Show all the users
    return HTTP.get('promo/')
  },

  update (id, body) {
    return HTTP.put('promo/' + id, body)
  },

  create (body) {
    return HTTP.post('promo/', body)
  },

  delete (id) {
    return HTTP.delete('promo/' + id)
  }
}

export default PromoResource
