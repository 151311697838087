import store from '../../store'

export default (to, from, next) => {
  const calendar = store.state.calendar.calendar || []
  const templates = store.state.templates.templates || []
  const types = store.state.type.types || []
  const sessions = store.state.session.sessions || []
  const promise = new Promise((resolve) => resolve(true))

  promise
    .then(() => {
      if (calendar.length === 0) {
        return store.dispatch('getCalendar')
      }
    })
    .then(() => {
      if (sessions.length === 0) {
        return store.dispatch('getSessions')
      }
    })
    .then(() => {
      if (templates.length === 0) {
        return store.dispatch('getTemplates')
      }
    })
    .then(() => {
      if (types.length === 0) {
        return store.dispatch('getTypes')
      }
    })
    .then(() => {
      return store.dispatch('getCapacityPast')
    })
    .finally(() => {
      next()
    })
}
