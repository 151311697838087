import HTTP from './http-common'

let UserResource = {
  show () { // Show all the users
    return HTTP.get('users/')
  },

  me () { // Get current user
    return HTTP.get('users/me')
  },

  update (id, body) {
    return HTTP.put('users/' + id, body)
  },

  create (body) {
    return HTTP.post('users/', body)
  },

  delete (id) {
    return HTTP.delete('users/' + id)
  }
}

export default UserResource
